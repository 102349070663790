import { MAIN_API } from "../../environment";
import Request from "../utils/request";

let service = {
  login: (formParams) => {
    return Request({
      url: "/api/Access/Login",
      method: "post",
      data: formParams,
    });
  },

  sendRegistrationCode: (email) => {
    return Request({
      url: "/api/Access/SendRegistrationCode",
      method: "get",
      params: { email: email },
    });
  },
  register: (formParams) => {
    return Request({
      url: "/api/Access/Register",
      method: "post",
      data: formParams,
    });
  },
  getMyProfile: () => {
    return Request({
      url: "/api/Access/Profile",
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
  sendResetPasswordCode: (email) => {
    return Request({
      url: "/api/Access/SendResetPasswordCode",
      method: "get",
      params: { email: email },
    });
  },
  resetPassword: (formParams) => {
    return Request({
      url: "/api/Access/ResetPassword",
      method: "post",
      data: formParams,
    });
  },

  updateProfile: (formParams) => {
    return Request({
      url: "/api/Access/Profile",
      method: "put",
      data: formParams,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
  gameAccountLogin: (formParams) => {
    return Request({
      baseURL: MAIN_API,
      url: "/api/Access/Login",
      method: "post",
      data: formParams,
      withCredentials: false,
    });
  },
};

export default service;


import { checkEmail } from "@/utils/validation";
import { FormInstance } from "element-plus";
import { ref, reactive } from "vue";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";

@Options({
  computed: {
    testerInfo() {
      return this.store.state.whiteList.whiteListItem;
    },
  },
})
export default class AddTesterView extends Vue {
  store = useStore();
  ruleFormRef = ref<FormInstance>();
  ruleForm = reactive({
    email: "",
  });
  rules = reactive({
    email: [{ validator: checkEmail, trigger: "blur" }],
  });
  check(formEl) {
    if (!formEl) return;
    formEl.validate((valid) => {
      if (valid) {
        this.store.dispatch("searchWhiteListItem", this.ruleForm.email);
      }
    });
  }
  add(email) {
    this.store.dispatch("addWhiteList", { email: email }).then(() => {
      this.ruleForm.email = "";
    });
  }
}

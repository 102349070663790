import { ElLoading, ElMessage } from "element-plus";
import GlobalService from "@/api/global.service";

const openFullScreen2 = () => {
  return ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
};

const contactUs = {
  state: {
    faqs: [],
    docs: [],
    setting: {},
  },
  mutations: {
    setFAQs(state, data) {
      state.faqs = data;
    },
    setDoc(state, data) {
      state.docs = data;
    },
    setSetting(state, data) {
      state.setting = {
        ...data,
        pricePermonthToString:
          data.partnerSubscriptionUnitPricePermonth > 0
            ? `${data.partnerSubscriptionUnitPricePermonth.toFixed(2)} USD`
            : `Free`,
      };
    },
  },
  actions: {
    contactUs(context, body) {
      const loading = openFullScreen2();
      return GlobalService.contactUs(body)
        .then((res) => {
          ElMessage.success(
            "Your partnership enquiry have been sent, we will get back you soon, thanks!"
          );
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    getFAQ(context) {
      const loading = openFullScreen2();
      return GlobalService.getFAQ()
        .then((res) => {
          context.commit("setFAQs", res.data);
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    getDoclist(context) {
      const loading = openFullScreen2();
      return GlobalService.getDoclist()
        .then((res) => {
          context.commit("setDoc", res.data);
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    getDocItem(context, key) {
      const loading = openFullScreen2();
      return GlobalService.getDocItem(key)
        .then((res) => {
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    getMdSource(context, url) {
      const loading = openFullScreen2();
      return GlobalService.getMdSource(url)
        .then((res) => {
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    getLanguage() {
      const loading = openFullScreen2();
      return GlobalService.getErrorCode()
        .then((res) => {
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    getSetting(context) {
      const loading = openFullScreen2();
      return GlobalService.getSetting()
        .then((res) => {
          context.commit("setSetting", res);
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    getPP() {
      const loading = openFullScreen2();
      return GlobalService.getPP()
        .then((res) => {
          res.replace(/#fff"/g, "#727272");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    getTC() {
      const loading = openFullScreen2();
      return GlobalService.getTC()
        .then((res) => {
          res.replace(/#fff"/g, "#727272");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    getTemplate(context, type) {
      const loading = openFullScreen2();
      return GlobalService.getStatic(type)
        .then((res) => {
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    getAsset(context, prop) {
      const loading = openFullScreen2();
      return GlobalService.getAsset(prop)
        .then((res) => {
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};

export default contactUs;

import { ElLoading } from "element-plus";
import ModelService from "@/api/model.service";
import { ElMessage } from "element-plus";
import {
  ProductVersionList,
  ReleaseVersionData,
} from "../../model/submission.model";

const openFullScreen2 = () => {
  return ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
};
const model = {
  state: {
    testingVersion: {},
    releaseVersion: {},
    historyVersions: [],

    releaseVersionFormattedData: {},
    historyVersionsFormattedData: [],

    productInfo: {},
    modelCategoryList: [],
  },
  mutations: {
    setModelInfo(state, data) {
      state.productInfo = data;
    },
    setModelCategoryList(state, data) {
      state.modelCategoryList = data?.data ? data?.data : [];
    },
    setVersionInfo(state, data) {
      state.testingVersion = data.testingVersion;
      state.releaseVersion = data.releaseVersion;
      state.historyVersions = data.historyVersions;
      state.historyVersionsFormattedData =
        data.historyVersions && new ProductVersionList(data.historyVersions);
      state.releaseVersionFormattedData =
        data.releaseVersion && new ReleaseVersionData(data.releaseVersion);
    },
  },
  actions: {
    createModel(context, data) {
      const loading = openFullScreen2();
      return ModelService.createModel(data)
        .then((res) => {
          ElMessage.success("create successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    updateModel(context, data) {
      const loading = openFullScreen2();
      return ModelService.updateModel(data.body, data.id)
        .then((res) => {
          ElMessage.success("update successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    getModelItem(context, id) {
      const loading = openFullScreen2();
      return ModelService.getModelItem(id)
        .then((res) => {
          context.commit("setModelInfo", res);
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    getModelCategoryList(context) {
      const loading = openFullScreen2();
      return ModelService.getModelCategoryList()
        .then((res) => {
          context.commit("setModelCategoryList", res);
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    getModels() {
      const loading = openFullScreen2();
      return ModelService.getModels()
        .then((res) => {
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    getModelVersions(context, id) {
      const loading = openFullScreen2();
      return ModelService.getModelVersions(id)
        .then((res) => {
          context.commit("setVersionInfo", res);
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    addModelVersion(context, data) {
      const loading = openFullScreen2();
      return ModelService.addVersion(data)
        .then((res) => {
          ElMessage.success("add version successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    getModelVersion(context, id) {
      const loading = openFullScreen2();
      return ModelService.getVersion(id)
        .then((res) => {
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    updateModelVersion(context, { data, id }) {
      const loading = openFullScreen2();
      return ModelService.updateVersion(data, id)
        .then((res) => {
          ElMessage.success("set version successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    deleteModel(context, id) {
      const loading = openFullScreen2();
      return ModelService.deleteModel(id)
        .then((res) => {
          ElMessage.success("delete successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    submitModelVersion(context, { prodId, vId }) {
      const loading = openFullScreen2();
      return ModelService.submitVersion(prodId, vId)
        .then((res) => {
          ElMessage.success("submit successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    takeDownModelVersion(context, id) {
      const loading = openFullScreen2();
      return ModelService.takeDownVersion(id)
        .then((res) => {
          ElMessage.success("take down successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};

export default model;

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5936c9ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common-del-wrapper" }
const _hoisted_2 = { class: "delete-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mit_button = _resolveComponent("mit-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.content), 1),
    _createElementVNode("div", null, [
      _createVNode(_component_mit_button, {
        onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel())),
        name: 'Cancel',
        btnStyle: 'blank-btn'
      }),
      _createVNode(_component_mit_button, {
        onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteItem())),
        name: _ctx.btnName
      }, null, 8, ["name"])
    ])
  ]))
}

import { reactive, ref } from "vue";
import { ElMessage, FormInstance } from "element-plus";
import { Options, Vue } from "vue-class-component";
import { toJson } from "@/utils/utility";
import { useStore } from "vuex";
import router from "@/router";
import { checkEmail, validatePass, validatePass2 } from "@/utils/validation";
import MitDialogVue from "@/components/ui-library/MitDialog.vue";
import { TemplateType } from "@/utils/constant.data";

@Options({
  components: {
    "mit-dialog": MitDialogVue,
  },
})
export default class RegisterForm extends Vue {
  ruleFormRef = ref<FormInstance>();
  store = useStore();
  router = router;
  tcAndpp = ref(false);
  dialogVisible = false;
  dialogTitle = "";
  ruleForm = reactive({
    password: "",
    checkPass: "",
    firstName: "",
    lastName: "",
    email: "",
    introduce: "",
    verificationCode: "",
    registeredBusinessName: "",
    registeredAddress: "",
  });
  countDown = 0;
  dialogText;
  readonly TemplateType = TemplateType;
  validatePass = (rule: any, value: any, callback: any) => {
    validatePass(
      rule,
      value,
      callback,
      this.ruleForm.checkPass,
      this.ruleFormRef,
      "checkPass"
    );
  };
  validatePass2 = (rule: any, value: any, callback: any) => {
    validatePass2(rule, value, callback, this.ruleForm.password);
  };

  rules = reactive({
    password: [{ validator: this.validatePass, trigger: "blur" }],
    checkPass: [{ validator: this.validatePass2, trigger: "blur" }],
    firstName: [
      { required: true, message: "Please input first name", trigger: "blur" },
    ],
    lastName: [
      { required: true, message: "Please input last name", trigger: "blur" },
    ],
    email: [{ validator: checkEmail, trigger: "blur" }],
    verificationCode: [
      {
        required: true,
        message: "Please input verification code",
        trigger: "blur",
      },
    ],
    registeredAddress: [
      {
        required: true,
        message: "Please input registered address",
        trigger: "blur",
      },
    ],
  });

  submitForm = (formEl: FormInstance | undefined) => {
    if (!this.tcAndpp) {
      ElMessage.warning(
        "Please accept terms and conditions and Privacy Policy."
      );
      return;
    }
    const formVal = toJson(this.ruleForm);
    if (!formEl) return;
    formEl.validate((valid) => {
      if (valid) {
        this.store.dispatch("register", formVal).then((res) => {
          this.router.push({ path: "/start-submission" });
        });
      } else {
        return false;
      }
    });
  };

  resetForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    formEl.resetFields();
  };

  sendCode(formEl: any) {
    formEl.validateField("email", (isValid) => {
      if (isValid) {
        this.countDown = 30;
        const timer = setInterval(() => {
          this.countDown--;
          if (this.countDown === 0) {
            clearInterval(timer);
          }
        }, 1000);
        // updating, need a count down.
        this.store.dispatch("sendRegistrationCode", this.ruleForm.email);
      }
    });
  }
  showDialog(type: TemplateType) {
    switch (type) {
      case TemplateType.DeveloperAgreement:
        this.dialogTitle = "Developer Agreement";
        break;
      case TemplateType.MarketplaceAgreement:
        this.dialogTitle = "Marketplace Agreement";
        break;
      case TemplateType.MarketplaceTermsofUse:
        this.dialogTitle = "Marketplace Terms of Use";
    }
    this.store
      .dispatch("getTemplate", type)
      .then((res) => {
        this.dialogText = res;
        this.dialogVisible = true;
        setTimeout(() => {
          const ele = document.getElementById("dialogInner") as any;
          ele.scrollTop = 0;
        }, 0);
      })
      .catch(() => {
        this.dialogVisible = false;
      });
  }
  updateVisible(val) {
    this.dialogVisible = val;
  }
}

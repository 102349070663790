
import router from "@/router";
import { checkInt } from "@/utils/validation";
import { FormInstance } from "element-plus";
import { ref, reactive } from "vue";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import {
  ProductVersionCreateMode,
  ModelVersionCreateMode,
} from "@/model/submission.model";
@Options({
  props: {
    prodId: String,
    isGame: Boolean,
  },
})
export default class AddVersionView extends Vue {
  ruleFormRef = ref<FormInstance>();
  ruleForm = reactive({
    version: "",
  });
  rules = reactive({
    version: [{ validator: checkInt, trigger: "blur" }],
  });
  store = useStore();
  router = router;
  isGame;
  prodId;
  submitForm(formEl: FormInstance | undefined) {
    if (!formEl) return;
    formEl.validate((valid) => {
      if (valid) {
        if (this.isGame) {
          this.store
            .dispatch(
              "addVersion",
              new ProductVersionCreateMode(this.ruleForm.version, this.prodId)
            )
            .then((res) => {
              this.$emit("addSuccess", res.id);
            });
        } else {
          this.store
            .dispatch(
              "addModelVersion",
              new ModelVersionCreateMode(this.ruleForm.version, this.prodId)
            )
            .then((res) => {
              this.$emit("addSuccess", res.id);
            });
        }
      } else {
        console.log("error submit!");
        return false;
      }
    });
  }
}


import { toLocaleDateString } from "@/utils/utility";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  computed: {
    subscriptionRange() {
      const isSubscribed = this.$store.state.user?.userProfile?.isSubscribed;
      const subscriptionExpiredAt =
        this.$store.state.user?.userProfile?.subscriptionExpiredAt;
      const fromDate = isSubscribed
        ? new Date(subscriptionExpiredAt)
        : new Date();
      const toDate = new Date(fromDate).setDate(fromDate.getDate() + 31);
      return `From ${toLocaleDateString(fromDate)} to ${toLocaleDateString(
        toDate
      )}`;
    },
    partnerSubscriptionUnitPricePermonth() {
      return this.$store.state.global?.setting
        ?.partnerSubscriptionUnitPricePermonth;
    },
    pricePermonthToString() {
      return this.$store.state.global?.setting?.pricePermonthToString;
    },
  },
})
export default class VPSView extends Vue {
  navigate() {
    this.$store.dispatch("getStripePayVPSUrl").then((res) => {
      if (res.isNeedToPay) {
        window.location = res.paymentUrl;
      } else {
        this.$router.push("/vps-placement");
        this.$store.dispatch("getMyProfile");
      }
    });
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_mit_button = _resolveComponent("mit-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "ruleFormRef",
    model: _ctx.ruleForm,
    "status-icon": "",
    rules: _ctx.rules,
    size: 'large',
    class: "input-wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { prop: "version" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.ruleForm.version,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ruleForm.version) = $event)),
            type: "number",
            autocomplete: "off",
            placeholder: "Version Number",
            class: "my-field"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_mit_button, {
        onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submitForm(_ctx.ruleFormRef))),
        name: 'Add',
        size: 'lg'
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}

import { Options, Vue } from "vue-class-component";
import HeaderNav from "@/components/layout/HeaderNav.vue";
import HeaderBanner from "@/components/layout/HeaderBanner.vue";
import FooterBar from "@/components/layout/FooterBar.vue";
import i18n from "@/utils/language";
import { NonBanner, FullPage } from "@/utils/constant.data";
@Options({
  components: {
    HeaderNav,
    HeaderBanner,
    FooterBar,
  },
  computed: {
    showBanner() {
      return !NonBanner.find((item) => item === this.$route.path);
    },
    fullPage() {
      return !!FullPage.find((item) => item === this.$route.path);
    },
  },
})
export default class App extends Vue {
  created() {
    this.$store.dispatch("getLanguage").then((res) => {
      i18n.global.setLocaleMessage("en", res);
    });
    if (localStorage.getItem("platform_accesstoken")) {
      this.$store.commit(
        "setAccessToken",
        localStorage.getItem("platform_accesstoken")
      );
      this.$store.dispatch("getMyProfile");
    }
    this.$store.dispatch("getSetting");
  }
}

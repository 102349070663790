import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CaretTop = _resolveComponent("CaretTop")!
  const _component_CaretBottom = _resolveComponent("CaretBottom")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_DeleteFilled = _resolveComponent("DeleteFilled")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.terminalClass)
  }, [
    _createElementVNode("div", {
      class: "collapse-btn",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed))
    }, [
      _createVNode(_component_el_icon, null, {
        default: _withCtx(() => [
          (_ctx.collapsed)
            ? (_openBlock(), _createBlock(_component_CaretTop, { key: 0 }))
            : (_openBlock(), _createBlock(_component_CaretBottom, { key: 1 }))
        ]),
        _: 1
      })
    ]),
    (!_ctx.collapsed)
      ? (_openBlock(), _createBlock(_component_el_icon, {
          key: 0,
          class: "clear",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clear()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DeleteFilled)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.collapsed)
      ? _renderSlot(_ctx.$slots, "default", {
          key: 1,
          class: "ani"
        }, undefined, true)
      : _createCommentVNode("", true)
  ], 2))
}

import { Options, Vue } from "vue-class-component";
import { reactive, ref } from "vue";
import { FormInstance } from "element-plus";
import { SignalRGameAction } from "@/utils/constant.data";
import { SignalRGame } from "@/model/game.model";
@Options({
  props: {
    gameIdSource: String,
  },
  watch: {
    gameIdSource(newVal) {
      this.ruleForm.gameId = newVal;
    },
  },
  data() {
    return {
      SignalRGameAction: SignalRGameAction,
    };
  },
})
export default class SetGameInfoView extends Vue {
  ruleFormRef = ref<FormInstance>();
  ruleForm = reactive({
    gameId: "",
    myGameInfo: "",
  });
  rules = reactive({
    gameId: [
      { required: true, message: "Please input Game ID", trigger: "blur" },
    ],
    myGameInfo: [
      {
        required: true,
        message: "Please input Game Information",
        trigger: "blur",
      },
    ],
  });
  submitForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    formEl.validate((valid) => {
      if (valid) {
        this.$emit(
          "trigger",
          new SignalRGame(
            SignalRGameAction.SetMyGameInfo,
            this.ruleForm.gameId,
            this.ruleForm.myGameInfo
          )
        );
      } else {
        console.log("error submit!");
        // return false;
      }
    });
  };
}

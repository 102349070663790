
import { Options, Vue } from "vue-class-component";
import { reactive, ref } from "vue";
import { FormInstance } from "element-plus";
import { SignalRGame } from "@/model/game.model";
import { SignalRGameAction } from "@/utils/constant.data";

@Options({
  props: {
    gameIdSource: String,
  },
  watch: {
    gameIdSource(newVal) {
      this.ruleForm.gameId = newVal;
    },
  },
})
export default class GameInformation extends Vue {
  ruleFormRef = ref<FormInstance>();
  ruleForm = reactive({
    gameId: "",
  });
  rules = reactive({
    gameId: [
      { required: true, message: "Please input Game ID", trigger: "blur" },
    ],
  });
  submitForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    formEl.validate((valid) => {
      if (valid) {
        // this.$emit("getInfo", this.ruleForm.gameId);
        this.$emit(
          "trigger",
          new SignalRGame(
            SignalRGameAction.GetGameInformation,
            this.ruleForm.gameId
          )
        );
      } else {
        console.log("error submit!");
        // return false;
      }
    });
  };
}

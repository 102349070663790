import { createStore } from "vuex";
import user from "./modules/user";
import uploader from "./modules/uploader";
import product from "./modules/product";
import whiteList from "./modules/whitelist";
import global from "./modules/global";
import model from "./modules/model";
import stripe from "./modules/stripe";
import stage from "./modules/stage";
import subscription from "./modules/subscription";

export default createStore({
  state: {
    loading: false,
  },
  getters: {},
  mutations: {
    updateLoadingState(state, loadingState) {
      state.loading = loadingState;
    },
  },
  actions: {},
  modules: {
    user,
    uploader,
    product,
    whiteList,
    global,
    model,
    stripe,
    stage,
    subscription,
  },
});


import { Options, Vue } from "vue-class-component";
import MyProjectsView from "./MyProjectsView.vue";

@Options({
  components: {
    MyProjectsView,
  },
  computed: {
    showRootDevPage() {
      return this.$route.name === "My Projects";
    },
  },
})
export default class MyProjectRouterView extends Vue {}

export const checkRequired = (
  rule: any,
  value: any,
  callback: any,
  message?
) => {
  if (!value) {
    return callback(
      new Error(
        typeof message === "string" ? message : "Please input the value"
      )
    );
  } else {
    callback();
  }
};

export const checkEmail = (rule: any, value: any, callback: any) => {
  const regu = /^\s*([A-Za-z0-9_-]+(\.\w+)*@(\w+\.)+\w{1,15})\s*$/;
  if (!value) {
    callback(new Error("Please input the email"));
  } else if (!regu.test(value)) {
    callback(new Error("Incorrect email format"));
  }
  callback();
};
export const checkInt = (rule: any, value: any, callback: any) => {
  const regu = /^[1-9]\d*$/;
  if (!value) {
    callback(new Error("Please input the value"));
  } else if (!regu.test(value)) {
    callback(new Error("Please enter a positive integer"));
  }
  callback();
};
export const validatePass = (
  rule: any,
  value: any,
  callback: any,
  ruleFormCheckPass,
  ruleFormRef,
  checkPassName: string
) => {
  const regu = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;
  if (value === "") {
    callback(new Error("Please input the password"));
  } else {
    if (!regu.test(value)) {
      callback(new Error("Password format incorrect"));
    } else if (ruleFormCheckPass !== "") {
      if (value) {
        ruleFormRef.validateField(checkPassName, () => null);
      }
    }
    callback();
  }
};

export const validatePass2 = (
  rule: any,
  value: any,
  callback: any,
  ruleFormPassword
) => {
  if (value === "") {
    callback(new Error("Please input the password again"));
  } else if (value !== ruleFormPassword) {
    callback(new Error("Two inputs don't match!"));
  } else {
    callback();
  }
};

export const checkNumNonnegative = (rule: any, value: any, callback: any) => {
  if (value === null || value === undefined) {
    return callback(new Error("Please input a valid value"));
  }
  if (value < 0) {
    callback(new Error("Please input a valid value"));
  } else {
    callback();
  }
};

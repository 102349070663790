import { ElLoading } from "element-plus";
import UploadService from "@/api/upload.service";
import { UploadItem } from "@/model/upload.model";
const openFullScreen2 = () => {
  return ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
};
const uploader = {
  actions: {
    multiUpload(context, fileArray) {
      const loading = openFullScreen2();
      return UploadService.multiUpload(fileArray)
        .then((res) => {
          return res;
        })
        .catch((imError) => {
          console.log(imError);
        })
        .finally(() => {
          loading.close();
        });
    },

    multiDetailUpload(
      context,
      { fileArray: fileArray, mergeArray: mergeArray }
    ) {
      const loading = openFullScreen2();
      return UploadService.multiDetailUpload(filterToUploadFile(fileArray))
        .then((uploadedList) => {
          if (mergeArray) {
            return fileArray.map((item) => {
              if (item.raw) {
                return uploadedList.find((uploaded) => {
                  return uploaded.name === item.name;
                });
              }
              return item;
            });
          }
          return uploadedList;
        })
        .catch((imError) => {
          console.log(imError);
        })
        .finally(() => {
          loading.close();
        });
    },

    singleUpload(context, file) {
      const loading = openFullScreen2();
      return UploadService.singleUpload(file)
        .then((res) => {
          return res;
        })
        .catch((imError) => {
          console.log(imError);
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};

export default uploader;

function filterToUploadFile(fileList) {
  const array = [];
  fileList.forEach((item) => {
    if (item.raw) {
      array.push(new UploadItem(item.raw.name, item.raw));
    }
  });
  return array;
}

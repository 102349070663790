import ProductService from "@/api/product.service";
import { ElMessage, ElLoading } from "element-plus";
import {
  ReleaseVersionData,
  ProductVersionList,
} from "../../model/submission.model";

const openFullScreen2 = () => {
  return ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
};
const uploader = {
  state: {
    testingVersion: {},
    releaseVersion: {},
    historyVersions: [],

    releaseVersionFormattedData: {},
    historyVersionsFormattedData: [],

    productInfo: {},
    allProducts: [],
  },
  mutations: {
    setProductInfo(state, data) {
      state.productInfo = data;
    },
    setVersionInfo(state, data) {
      state.testingVersion = data.testingVersion;
      state.releaseVersion = data.releaseVersion;
      state.historyVersions = data.historyVersions;
      state.historyVersionsFormattedData =
        data.historyVersions && new ProductVersionList(data.historyVersions);
      state.releaseVersionFormattedData =
        data.releaseVersion && new ReleaseVersionData(data.releaseVersion);
    },
    setAllProducts(state, res) {
      state.allProducts = res.data;
    },
  },
  actions: {
    createProduct(context, data) {
      const loading = openFullScreen2();
      return ProductService.createProduct(data)
        .then((res) => {
          ElMessage.success("create successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    getProducts(context, data) {
      const loading = openFullScreen2();
      return ProductService.getProduct()
        .then((res) => {
          context.commit("setAllProducts", res);
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    getProductItem(context, id) {
      const loading = openFullScreen2();
      return ProductService.getProductItem(id)
        .then((res) => {
          context.commit("setProductInfo", res);
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    getProductVersions(context, id) {
      const loading = openFullScreen2();
      return ProductService.getProductVersions(id)
        .then((res) => {
          context.commit("setVersionInfo", res);
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    deleteProduct(context, id) {
      const loading = openFullScreen2();
      return ProductService.deleteProduct(id)
        .then((res) => {
          ElMessage.success("delete successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    updateProduct(context, data) {
      const loading = openFullScreen2();
      return ProductService.updateProduct(data.body, data.id)
        .then((res) => {
          ElMessage.success("update successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    takeDownVersion(context, id) {
      const loading = openFullScreen2();
      return ProductService.takeDownVersion(id)
        .then((res) => {
          ElMessage.success("take down successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    submitVersion(context, { prodId, vId }) {
      const loading = openFullScreen2();
      return ProductService.submitVersion(prodId, vId)
        .then((res) => {
          ElMessage.success("submit successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    addVersion(context, data) {
      const loading = openFullScreen2();
      return ProductService.addVersion(data)
        .then((res) => {
          ElMessage.success("add version successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    updateVersion(context, { data, id }) {
      const loading = openFullScreen2();
      return ProductService.updateVersion(data, id)
        .then((res) => {
          ElMessage.success("set version successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    getVersion(context, id) {
      const loading = openFullScreen2();
      return ProductService.getVersion(id)
        .then((res) => {
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    getRecentProduct(context) {
      const loading = openFullScreen2();
      return ProductService.getRecentProduct()
        .then((res) => {
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};

export default uploader;

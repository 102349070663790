
export default {
  props: {
    visible: Boolean,
    title: String,
  },

  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("updateVisible", val);
      },
    },
  },

  methods: {
    onClose() {
      this.$emit("resetPopupData");
    },
  },
};

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_mit_button = _resolveComponent("mit-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_form, {
      ref: "ruleFormRef",
      model: _ctx.ruleForm,
      "status-icon": "",
      rules: _ctx.rules,
      size: 'large',
      class: "input-wrapper"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, {
          gutter: 10,
          justify: "center"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 10 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, { prop: "email" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.ruleForm.email,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ruleForm.email) = $event)),
                      type: "text",
                      autocomplete: "off",
                      placeholder: "MMITA User Registered Email",
                      class: "my-field"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_mit_button, {
                  onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.add())),
                  name: _ctx.addName
                }, null, 8, ["name"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_el_table, {
      data: _ctx.whiteList,
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "email",
          label: "Email"
        }),
        _createVNode(_component_el_table_column, {
          prop: "fullName",
          label: "UserName"
        }),
        _createVNode(_component_el_table_column, {
          label: "Operations",
          align: "right"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_mit_button, {
              onOnClick: ($event: any) => (_ctx.remove(scope.row)),
              name: _ctx.name,
              size: _ctx.size,
              btnStyle: _ctx.style
            }, null, 8, ["onOnClick", "name", "size", "btnStyle"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])
  ], 64))
}

import { Options, Vue } from "vue-class-component";
import { CaretBottom, CaretTop, DeleteFilled } from "@element-plus/icons-vue";

@Options({
  components: {
    CaretBottom,
    CaretTop,
    DeleteFilled,
  },
  computed: {
    terminalClass() {
      return this.collapsed ? "collapsed-wrapper" : "terminal-wrapper";
    },
  },
  inject: ["clear"],

  // mounted() {
  //   // this.handleResize()
  // },
})
export default class MitTerminal extends Vue {
  collapsed = false;
  startDragFlag = false;
  orgClientY;
  toSetClientY;
  toSetHeightStyle = "100px";
  handleResize() {
    const resize = document.getElementById("resize") as HTMLElement;
    const app = document.getElementById("app") as HTMLElement;
    resize.onmousedown = (ev) => {
      console.log("resize", ev);
      // this.startDragFlag = true
      // this.orgClientY = ev.clientY
    };

    app.onmouseup = (ev) => {
      console.log("app", ev);
      this.toSetClientY = ev.clientY;
      console.log(app.offsetHeight);
      console.log((app as any)?.innerHeight);

      this.startDragFlag = false;

      if (this.orgClientY == this.toSetClientY) {
        return;
      }
      this.toSetHeightStyle = app.offsetHeight - this.toSetClientY + "px";
    };
  }
}

import { ElLoading, ElMessage } from "element-plus";
import WhiteListService from "@/api/whitelist.service";

const openFullScreen2 = () => {
  return ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
};
const whiteList = {
  state: {
    whiteListItem: {},
    whiteList: [],
  },
  mutations: {
    setWhiteListItem(state, data) {
      state.whiteListItem = data;
    },
    setWhiteList(state, data) {
      state.whiteList = data;
    },
    pushWhiteList(state, data) {
      state.whiteList = [...state.whiteList, data];
    },
    removeItem(state, id) {
      let idx;
      state.whiteList.forEach((item, index) => {
        if (item.id === id) {
          return (idx = index);
        }
      });

      state.whiteList.splice(idx, 1);
    },
  },
  actions: {
    getWhiteList(context) {
      const loading = openFullScreen2();
      return WhiteListService.getWhiteList()
        .then((res) => {
          context.commit("setWhiteList", res);
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    removeWhiteListItem(context, id) {
      const loading = openFullScreen2();
      return WhiteListService.removeWhiteListItem(id)
        .then((res) => {
          ElMessage.success("Remove Successfully");
          context.commit("removeItem", id);
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    addWhiteList(context, body) {
      const loading = openFullScreen2();
      return WhiteListService.addWhiteList(body)
        .then((res) => {
          ElMessage.success("Add WhiteList Successfully");
          context.commit("setWhiteListItem", null);
          context.commit("pushWhiteList", res);
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    searchWhiteListItem(context, email) {
      const loading = openFullScreen2();
      return WhiteListService.searchWhiteListItem(email)
        .then((res) => {
          if (!res) {
            ElMessage.info("No user information");
          }
          context.commit("setWhiteListItem", res);
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};

export default whiteList;

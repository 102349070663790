import Request from "../utils/request";
import { PARTNER_WESITE_URL } from "../../environment";
const prefixUrl = "/api/Subscription";

let service = {
  getSubscription: (params) => {
    return Request({
      url: `${prefixUrl}/Query`,
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
      params: {
        PageSize: 10,
        ...params,
      },
    });
  },

  getStripePayVPSUrl: () => {
    return Request({
      url: `${prefixUrl}/Purchase/Stripe`,
      method: "post",
      data: {
        quantity: 1,
        cancelUrl: `${PARTNER_WESITE_URL}/#/vps`,
        successUrl: `${PARTNER_WESITE_URL}/#/vps-placement`,
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
};

export default service;


import { Options, Vue } from "vue-class-component";
import MitMap from "@/components/ui-library/MitMap.vue";
import { Delete, Edit } from "@element-plus/icons-vue";
import { ref } from "vue";
import { toLocaleDateString } from "@/utils/utility";

@Options({
  components: {
    MitMap,
    Delete,
    Edit,
  },
  computed: {
    totalStageCount() {
      return this.$store.state.stage.totalStageCount;
    },
    currentPageData() {
      return this.$store.state.stage.currentPageStage.map((item) => {
        return { ...item, active: !item.isInactive };
      });
    },
    ExpiredDate() {
      const subscriptionExpiredAt =
        this.$store.state.user?.userProfile?.subscriptionExpiredAt;
      return subscriptionExpiredAt
        ? `Expiry date: ${toLocaleDateString(subscriptionExpiredAt)}`
        : `Expired`;
    },
    isSubscribed() {
      return this.$store.state.user?.userProfile?.isSubscribed;
    },
  },
})
export default class VPSPlacementView extends Vue {
  type = ref("");
  isInactive = ref(null);
  pageIndex = 1;
  focusOnMarker = { lat: 0, lng: 0 };
  navigateTo(url, query) {
    this.$router.push({ path: url, query: query });
  }
  created() {
    this.$store.dispatch("getStages");
  }
  dateLocalStringFormatter = (row: any, column: any, cellValue: any) => {
    return cellValue ? new Date(cellValue).toLocaleDateString() : "-";
  };

  typeFormatter = (row: any, column: any, cellValue: any) => {
    return cellValue === "VerticalCarousel" ? "Vertical Carousel" : cellValue;
  };
  deleteItem(id) {
    this.$store.dispatch("deleteStage", id).then(() => {
      this.getDataBySearchCriteria();
    });
  }

  getDataBySearchCriteria(pageIndex = 1) {
    this.pageIndex = pageIndex;
    this.$store.dispatch("getStages", {
      StageType: this.type,
      IsInactive: this.isInactive,
      PageIndex: pageIndex,
    });
  }
  changeActive(ev) {
    const data = { isInactive: !ev.active };
    this.$store
      .dispatch(ev.stageType === "Normal" ? "updateStage" : "updateVCStage", {
        param: data,
        id: ev.id,
      })
      .finally(() => {
        this.getDataBySearchCriteria();
      });
  }
  rowClick(row) {
    this.focusOnMarker = {
      lat: row?.latitude,
      lng: row?.longitude,
    };
  }
}

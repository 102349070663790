<template>
  <div class="about">
    <h1>This is an about page</h1>
    <!-- <el-button type="success">Success</el-button>
    <el-row :gutter="20">
      <el-col :span="6">123</el-col>
      <el-col :span="6">123</el-col>
      <el-col :span="6">123</el-col>
      <el-col :span="6">123</el-col>
    </el-row> -->
  </div>
</template>


import { Options, Vue } from "vue-class-component";
import { reactive, ref } from "vue";
import { toJson } from "@/utils/utility";
import { FormInstance } from "element-plus";
import { CreateGameBasicInfo } from "@/model/game.model";
import { SignalRGameAction } from "@/utils/constant.data";
import { checkInt } from "@/utils/validation";

@Options({
  props: {
    createMode: Boolean,
    gameIdSource: String,
    productVersionSource: Number,
    gameTypeSource: String,
  },
  watch: {
    gameIdSource(newVal) {
      this.ruleForm.gameId = newVal;
    },
    productVersionSource(newVal) {
      this.ruleForm.productVersion = newVal;
    },
    gameTypeSource(newVal) {
      this.ruleForm.gameType = newVal;
    },
  },
})
export default class CreateGameView extends Vue {
  ruleFormRef = ref<FormInstance>();
  readonly gameTypeOptions = [
    {
      value: "Default",
      label: "Default",
    },
    {
      value: "Survival",
      label: "Survival",
    },
  ];
  checkNumber = (
    rule: any,
    value: any,
    callback: any,
    greaterThan?: boolean
  ) => {
    if (!value) {
      return callback(new Error(`Please input the positive integer`));
    }
    if (!Number.isInteger(Number(value))) {
      callback(new Error("Please input digits"));
    } else if (value < 1) {
      callback(new Error("Must be non negative"));
    } else if (
      greaterThan === true &&
      Number(value) < Number(this.ruleForm.roundInfoMin)
    ) {
      callback(new Error("Must be garter than minimum number of rounds"));
    } else if (
      greaterThan === false &&
      Number(value) > Number(this.ruleForm.roundInfoMax)
    ) {
      callback(new Error("Must be less than maximum number of rounds"));
    } else {
      if (greaterThan === true) {
        this.ruleFormRef["validateField"]("roundInfoMin", () => null);
      } else if (greaterThan === false) {
        this.ruleFormRef["validateField"]("roundInfoMax", () => null);
      }
      callback();
    }
  };

  lessThanMax = (rule: any, value: any, callback: any) => {
    this.checkNumber(rule, value, callback, false);
  };

  graterThanMin = (rule: any, value: any, callback: any) => {
    this.checkNumber(rule, value, callback, true);
  };

  ruleForm = reactive({
    productId: "",
    totalTime: 60,
    roundInfoMin: 10,
    roundInfoMax: 15,
    totalRounds: 5,
    arId: "mmitatestarid",
    productVersion: "",
    gameId: "",
    gameType: "",
  });
  rules = reactive({
    gameId: [
      { required: true, message: "Please input Game ID", trigger: "blur" },
    ],
    productId: [
      { required: true, message: "Please input Product ID", trigger: "blur" },
    ],
    totalTime: [{ validator: checkInt, trigger: "blur" }],

    roundInfoMin: [
      {
        validator: this.lessThanMax,
        trigger: "blur",
      },
    ],
    roundInfoMax: [
      {
        validator: this.graterThanMin,
        trigger: "blur",
      },
    ],
    totalRounds: [
      {
        validator: checkInt,
        trigger: "blur",
      },
    ],
    arId: [{ required: true, message: "Please input AR ID", trigger: "blur" }],
    productVersion: [
      {
        required: true,
        message: "Please input Product Version",
        trigger: "blur",
      },
    ],
  });
  created() {
    this.ruleForm.productId = this.$route.query.prodId as any;
  }
  submitForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    formEl.validate((valid) => {
      if (valid) {
        const baseInfo = new CreateGameBasicInfo(toJson(this["ruleForm"]));
        if (this["createMode"]) {
          this.$emit("createGame", {
            actionName: SignalRGameAction.CreateGame,
            baseInfo: baseInfo,
            arId: this.ruleForm.arId,
          });
        } else {
          this.$emit("createGame", {
            actionName: SignalRGameAction.RestartGame,
            baseInfo: baseInfo,
            gameId: this.ruleForm.gameId,
          });
        }
      } else {
        console.log("error submit!");
        // return false;
      }
    });
  };
}


import { Options, Vue } from "vue-class-component";
import LoginForm from "@/components/view/login/LoginForm.vue";
import RegisterForm from "@/components/view/login/RegisterForm.vue";

@Options({
  components: {
    LoginForm,
    RegisterForm,
  },
  mounted() {
    window.scrollTo(0, 444);
  },
})
export default class LoginView extends Vue {}

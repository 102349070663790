import axios from "axios";
import { PARTNER_API } from "../../environment";
import { ElMessage } from "element-plus";
import router from "@/router";
import i18n from "@/utils/language";

const instance = axios.create({
  headers: {
    "content-type": "application/json;charset=utf8",
  },
});

instance.defaults.baseURL = PARTNER_API;

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error && error.response && error.response.status === 401) {
      ElMessage.error("The user has expired, please login again.");

      localStorage.removeItem("platform_accesstoken");
      router.push({ path: "/login" });

      throw error.response;
    } else {
      const errorText = error?.response?.data?.errorCode
        ? i18n.global.t((error?.response?.data?.errorCode).toString()) ||
          error.response.data.message
        : "Error";
      ElMessage.error(errorText);
      throw error.response;
    }
  }
);

export default instance;

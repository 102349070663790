import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a9c6eda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.failed ? "Failed to connect to stripe account" : "Connecting to MMITA") + " ", 1),
    _createElementVNode("div", {
      class: "link",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.naviagteTo()))
    }, "try again")
  ]))
}
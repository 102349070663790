
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { ProductStatus, ProductVersionStatus } from "@/utils/constant.data";
import MitUploader from "@/components/ui-library/MitUploader.vue";
import MitDialogVue from "@/components/ui-library/MitDialog.vue";
import CommonDelete from "@/components/view/dialog/CommonDelete.vue";
import { WarningFilled } from "@element-plus/icons-vue";
import AddVersionView from "@/components/view/dialog/AddVersion.vue";
import { ElMessage, ElMessageBox } from "element-plus";
enum DialogType {
  NewVersion,
  Delete,
  TakeDown,
}
@Options({
  components: {
    "mit-uploader": MitUploader,
    "mit-dialog": MitDialogVue,
    "common-delete": CommonDelete,
    "new-version": AddVersionView,
    WarningFilled,
  },
  computed: {
    connectedToStripe() {
      return this.store.state.user?.userProfile?.connectedToStripe;
    },
    isGame() {
      return this.$route.query.type === "AR Game";
    },
    source() {
      return this.isGame ? this.store.state.product : this.store.state.model;
    },
    versionKeyName() {
      return this.isGame ? "productVersion" : "modelVersion";
    },
    versionList() {
      return this.source.historyVersionsFormattedData;
    },

    releaseVersion() {
      return this.source.releaseVersion;
    },
    resubmitreleaseVersion() {
      return this.releaseVersion.reviewStatus === "PrepareForSubmission";
    },
    releaseVersionInfo() {
      return this.source.releaseVersionFormattedData;
    },
    hasTestingVersion() {
      return !!this.source.testingVersion?.id;
    },
    // 存在 testingVerison 或者 releaseVersion都不能上传，除非此时releaseVersion的状态为AcceptedAndReleased或者TakeDown
    disabledAddVersion() {
      return (
        this.hasTestingVersion ||
        (this.releaseVersion &&
          this.releaseVersion?.reviewStatus !== "AcceptedAndReleased" &&
          this.releaseVersion?.status !== "TakeDown")
      );
    },
    testingVersionInfo() {
      return this.source.testingVersion;
    },
    enableSubmitTestingVersion() {
      return this.testingVersionInfo?.reviewStatus === "PrepareForSubmission";
    },
    testingProductVersion() {
      return (
        this.source?.testingVersion &&
        this.source?.testingVersion[this.versionKeyName]
      );
    },
    enableSubmitForApproval() {
      return (
        this.testingVersionInfo.id &&
        (!this.source.releaseVersion ||
          this.source.releaseVersion.reviewStatus === "AcceptedAndReleased" ||
          this.source.releaseVersion.status === ProductVersionStatus.TakeDown)
      );
    },
    enableTakeDown() {
      return (
        this.source.releaseVersion.reviewStatus === "AcceptedAndReleased" &&
        this.source.releaseVersion.status === ProductVersionStatus.Active
      );
    },
    rejectedReleaseVersion() {
      return this.releaseVersion &&
        this.releaseVersion.reviewStatus === ProductStatus.Rejected
        ? this.releaseVersion
        : null;
    },
    releaseVersionTakeDown() {
      return this.releaseVersion && this.releaseVersion.status === "TakeDown";
    },
    mainInfo() {
      this.source.productInfo.status =
        ProductStatus[this.source.productInfo.status];
      return this.source.productInfo;
    },
    takeDownTooltip() {
      if (
        this.releaseVersion.takeDownRole === "Admins" &&
        this.releaseVersion.takeDownReason
      ) {
        return this.releaseVersion.takeDownReason;
      }
      return;
    },
    // to delete?
    rejectTooltip() {
      return this.releaseVersion.unapproveReason;
    },
    testingVersionRejectTooltip() {
      return this.testingVersionInfo.unapproveReason;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    const id = this.$route.query.id;
    if (this.isGame) {
      this.store.dispatch("getProductItem", id);
      this.store.dispatch("getProductVersions", id);
    } else {
      this.store.dispatch("getModelItem", id);
      this.store.dispatch("getModelVersions", id);
    }
  },
})
export default class ProjectDetailView extends Vue {
  store = useStore();
  dialogVisible = false;
  tableRowClassName = ({ row, rowIndex }: { row: any; rowIndex: number }) => {
    return row.statusRowClass;
  };
  connectedToStripe: boolean | undefined;
  readonly DialogType = DialogType;
  showDialogType: DialogType | undefined;
  dialogTitle: string | undefined;

  navigateTo(name, prop?) {
    switch (name) {
      case "Submission":
        this.$router.push({
          path: "/developers/my-project/submission",
          query: { id: this["mainInfo"].id },
        });
        break;
      case "TestStaging":
        // window.open(GAME_URL, "_blank");
        this.$router.push({
          name: "Test Staging Site",
          query: { prodId: this["mainInfo"].id },
        });
        break;
      case "Version":
        this.$router.push({
          path: "/developers/my-project/version",
          query: {
            prodId: this["mainInfo"].id,
            vId: prop,
            type: this.$route.query.type,
          },
        });
        break;
      case "Submission of 3D Models":
        this.$router.push({
          name: "Submission of 3D Models",
          query: { id: this["mainInfo"].id },
        });
        break;
    }
  }

  triggerEditVersion() {
    if (this["enableSubmitTestingVersion"]) {
      this.navigateTo("Version", this["testingVersionInfo"].id);
      return;
    }
    ElMessageBox.confirm(
      "The current version has entered the review process. If the current test version is modified, the review process will be interrupted. Are you sure you want to modify the current version information?",
      "Warning",
      {
        confirmButtonText: "Edit",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    )
      .then(() => {
        this.navigateTo("Version", this["testingVersionInfo"].id);
      })
      .catch();
  }

  onDelete() {
    this.store
      .dispatch(
        this["isGame"] ? "deleteProduct" : "deleteModel",
        this["mainInfo"].id
      )
      .then(() => {
        this.$router.push({ path: "/developers/my-project" });
      });
  }

  takeDown() {
    if (this["isGame"]) {
      this.store.dispatch("takeDownVersion", this["mainInfo"].id).then(() => {
        this.store.dispatch("getProductVersions", this["mainInfo"].id);
        this.updateVisible(false);
      });
    } else {
      this.store
        .dispatch("takeDownModelVersion", this["mainInfo"].id)
        .then(() => {
          this.store.dispatch("getModelVersions", this["mainInfo"].id);
          this.updateVisible(false);
        });
    }
  }

  submitVersion(submitVersionId) {
    if (this["isGame"]) {
      this.store
        .dispatch("submitVersion", {
          prodId: this["mainInfo"].id,
          vId: submitVersionId,
        })
        .then(() => {
          this.store.dispatch("getProductVersions", this["mainInfo"].id);
        });
    } else {
      this.store
        .dispatch("submitModelVersion", {
          prodId: this["mainInfo"].id,
          vId: submitVersionId,
        })
        .then(() => {
          this.store.dispatch("getModelVersions", this["mainInfo"].id);
        });
    }
  }

  updateVisible(val, dialogType?: DialogType) {
    if (dialogType === DialogType.NewVersion && !this.connectedToStripe) {
      this.$router.push({ path: "/stripe" });
      ElMessage.warning("Please connect the stripe account first.");
      return;
    }
    this.dialogVisible = val;
    this.showDialogType = dialogType;
    switch (this.showDialogType) {
      case DialogType.Delete:
        this.dialogTitle = "Confirm to Delete?";
        break;
      case DialogType.NewVersion:
        this.dialogTitle = "Add Version";
        break;
      case DialogType.TakeDown:
        this.dialogTitle = "Take Down";
        break;
    }
  }
  // 点击取消的事件
  resetPopupData() {
    this.dialogVisible = false;
  }
  addVersion(id) {
    this.updateVisible(false, DialogType.NewVersion);
    this.navigateTo("Version", id);
  }
}

import Request from "../utils/request";
import { MAIN_API } from "../../environment";

let service = {
  contactUs: (formParams) => {
    return Request({
      url: "/api/ContactUs",
      method: "post",
      data: formParams,
    });
  },

  getFAQ: () => {
    return Request({
      url: `/api/Faq`,
      method: "get",
    });
  },
  getDoclist: () => {
    return Request({
      url: `/api/Md/Query`,
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
  getMdSource: (url) => {
    return Request({
      url: url,
      method: "get",
      baseURL: "",
    });
  },
  getErrorCode: () => {
    return Request({
      url: `/api/Language/Localization/en-US`,
      method: "get",
    });
  },
  getSetting: () => {
    return Request({
      url: `/api/Setting`,
      method: "get",
    });
  },
  getDocItem: (key) => {
    return Request({
      url: `/api/Md/${key}`,
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },

  getStatic: (type) => {
    return Request({
      baseURL: MAIN_API,
      url: `/Static?type=${type}&culture=en-US`,
      method: "get",
      withCredentials: false,
    });
  },

  getAsset: (prop) => {
    return Request({
      url: `/api/Asset/Query`,
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
      params: {
        PageSize: -1,
        ...prop,
      },
    });
  },
};

export default service;

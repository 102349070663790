
import { Options, Vue } from "vue-class-component";
import { reactive, ref } from "vue";
import { FormInstance } from "element-plus";
import { SignalRGame } from "@/model/game.model";
import { SignalRGameAction } from "@/utils/constant.data";
import { toJson } from "@/utils/utility";

@Options({
  data() {
    return {
      Action: SignalRGameAction,
    };
  },
  props: {
    gameIdSource: String,
  },
  watch: {
    gameIdSource(newVal) {
      this.ruleForm.gameId = newVal;
    },
  },
})
export default class SetAchieveGoalInRound extends Vue {
  ruleFormRef = ref<FormInstance>();

  ruleForm = reactive({
    gameId: undefined,
    key: undefined,
    value: undefined,
  });
  rules = reactive({
    gameId: [
      { required: true, message: "Please input Game ID", trigger: "blur" },
    ],
    key: [{ required: true, message: "Please input Key", trigger: "blur" }],
    value: [{ required: true, message: "Please input Value", trigger: "blur" }],
  });

  submitForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    formEl
      .validate((valid) => {
        if (valid) {
          this.$emit(
            "trigger",
            new SignalRGame(
              SignalRGameAction.SetAchieveGoalInRound,
              this.ruleForm.gameId,
              toJson(this["ruleForm"])
            )
          );
        } else {
          console.log("error submit!");
        }
      })
      .catch();
  };
}

export class CreateGameBasicInfo {
  TotalTime: number;
  CustomContent: undefined; // Not used temporarily
  RoundInfo: RoundInfo;
  RoundUsersOrder = []; // Not used temporarily
  BroadcastMessage: undefined; // Not used temporarily
  ProductVersion: number;
  ProductId: string;
  GameType: string;
  constructor(data) {
    this.TotalTime = parseInt(data.totalTime);
    this.RoundInfo = new RoundInfo(data);
    this.ProductVersion = parseInt(data.productVersion);
    this.ProductId = data.productId;
    this.GameType = data.gameType;
  }
}

class RoundInfo {
  Min: number;
  Max: number;
  TotalRounds: number;
  constructor(data) {
    this.Min = parseInt(data.roundInfoMin);
    this.Max = parseInt(data.roundInfoMax);
    this.TotalRounds = parseInt(data.totalRounds);
  }
}

export class SignalRGame {
  actionName: string;
  gameId: string;
  prop: any;
  constructor(action, id?, prop?) {
    this.actionName = action;
    this.gameId = id;
    this.prop = prop;
  }
}
export class GameResult {
  actionName: string | undefined;
  succeeded: boolean | undefined;
  msgSate: string | undefined;
  time: string | undefined;
  data: any;
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b8df3c1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "row template-container" }
const _hoisted_3 = { class: "container text-left" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "sub-title" }
const _hoisted_6 = { class: "des" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_row, {
            gutter: 30,
            class: "content-wrapper"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.qaList, (item, index) => {
                return (_openBlock(), _createBlock(_component_el_col, {
                  span: 12,
                  key: index
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(item.question), 1),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(item.answer), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}

import { FormInstance } from "element-plus";
import { ref, reactive } from "vue";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { toJson } from "@/utils/utility";
import { CircleCheck } from "@element-plus/icons-vue";

@Options({
  components: {
    CircleCheck,
  },
  mounted() {
    window.scroll(0, 0);
    this.store.dispatch("getStripeUrl");
  },
  computed: {
    url() {
      const stripeUrlInfo = this.store.state.stripe?.stripeUrlInfo;
      return stripeUrlInfo.find((item) => item.accountType === "Standard")?.url; //temporary
    },
    connectedToStripe() {
      return this.store.state.user?.userProfile?.connectedToStripe;
    },
  },
})
export default class StripeView extends Vue {
  store = useStore();
  readonly options = ["Standard", "Express"];
  ruleFormRef = ref<FormInstance>();
  ruleForm = reactive({
    email: "",
    accountType: "Standard",
  });
  rules = reactive({
    email: [{ required: true, trigger: "blur" }],
    accountType: [{ required: true, trigger: "blur" }],
  });
  url: string | undefined;
  submitForm = (formEl: FormInstance | undefined, ruleForm) => {
    const formVal = toJson(ruleForm);
    if (!formEl) return;
    formEl.validate((valid) => {
      if (valid) {
        this.store.dispatch("sendEmail", formVal);
      } else {
        return false;
      }
    });
  };

  newTab() {
    window.open(this.url);
  }
}

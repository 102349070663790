
import { toJson } from "@/utils/utility";
import { checkEmail } from "@/utils/validation";
import { FormInstance } from "element-plus";
import { reactive, ref } from "vue";
import { Vue } from "vue-class-component";

export default class ContactUsForm extends Vue {
  ruleFormRef = ref<FormInstance>();
  ruleForm = reactive({
    name: "",
    company: "",
    phoneNumber: "",
    email: "",
    description: "",
  });
  rules = reactive({
    name: [{ required: true, message: "Please input name", trigger: "blur" }],
    email: [{ validator: checkEmail, trigger: "blur" }],
    description: [
      {
        required: true,
        message: "Please input description",
        trigger: "blur",
      },
    ],
  });
  submitForm(formEl: FormInstance | undefined) {
    if (!formEl) return;
    formEl.validate((valid) => {
      if (valid) {
        this.submit();
      } else {
        return false;
      }
    });
  }
  submit() {
    const formVal = toJson(this.ruleForm);
    this.$store.dispatch("contactUs", formVal).then(() => {
      this.$router.push({ name: "Home" });
    });
  }
}


import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { reactive, ref } from "vue";
import { FormInstance } from "element-plus";
import { checkEmail } from "@/utils/validation";

@Options({
  data() {
    return {
      size: ref("md"),
      name: ref("Remove"),
      style: ref("blank-btn"),
      addName: ref("Add to Whitelist"),
    };
  },
  mounted() {
    this.loadWhiteList();
  },
})
export default class ManageWhiteListView extends Vue {
  store = useStore();
  ruleFormRef = ref<FormInstance>();
  ruleForm = reactive({
    email: "",
  });
  rules = reactive({
    email: [{ validator: checkEmail, trigger: "blur" }],
  });
  whiteList: Array<any> = [];

  loadWhiteList() {
    this.store.dispatch("getWhiteList").then((res) => {
      this.whiteList = res.map((item) => {
        return {
          ...item,
          email: item.user.email,
          fullName: `${item.user.fullName}`,
        };
      });
      //   this.whiteList = [{ email: "111", fullName: "aaaa", id: "999" }];
    });
  }

  remove(rowData) {
    const id = rowData.id;
    this.store.dispatch("removeWhiteListItem", id).then(() => {
      this.loadWhiteList();
    });
  }

  add() {
    this.store
      .dispatch("addWhiteList", { email: this.ruleForm.email })
      .then(() => {
        this.loadWhiteList();
      });
  }
}

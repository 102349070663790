
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";

@Options({
  mounted() {
    window.scroll(0, 0);
    const code = this.$route.query.code;
    const scope = this.$route.query.scope;
    const stay = this.$route.query.stay;
    this.store
      .dispatch("confirm", { code: code, scope: scope })
      .then((res) => {
        this.store.commit("GET_USER_INFO", res);
        !stay && this.$router.push({ path: "/start-submission" });
      })
      .catch(() => {
        !stay && this.$router.push({ path: "/stripe" });
        this.failed = true;
      });
  },
})
export default class StripeConnectedView extends Vue {
  store = useStore();
  failed = false;

  naviagteTo() {
    this.$router.push({ path: "/stripe" });
  }
}


import { ref } from "vue";
import { ElMessage, genFileId } from "element-plus";
import { UploadFilled } from "@element-plus/icons-vue";
import { Options, Vue } from "vue-class-component";
import type { UploadInstance, UploadProps, UploadRawFile } from "element-plus";
import { UploadFileType } from "@/utils/constant.data";

@Options({
  components: {
    "upload-filled": UploadFilled,
  },
  props: {
    preview: false,
    description1: "",
    description2: "",
    type: UploadFileType,
    multiple: false,
  },
})
export default class MitUploader extends Vue {
  uploadRef: any = ref<UploadInstance>();
  fileSource: any;
  fileList = [];

  beforeUpload: UploadProps["beforeUpload"] = (rawFile) => {
    console.log(rawFile);
    switch (this["type"]) {
      case UploadFileType.IMAGE:
        if (rawFile.type !== "image/jpeg") {
          ElMessage.error("Avatar picture must be JPG format!");
          return false;
        } else if (rawFile.size / 1024 / 1024 > 5) {
          ElMessage.error("Avatar picture size can not exceed 5MB!");
          return false;
        }
        return true;
    }
  };

  checkFileType(rawFile, uploadFile) {
    switch (this["type"]) {
      case UploadFileType.IMAGE:
        if (rawFile.type !== "image/jpeg" && rawFile.type !== "image/png") {
          ElMessage.error("Avatar picture must be JPG or PNG format!");
          return false;
        } else if (rawFile.size / 1024 / 1024 > 5) {
          ElMessage.error("Avatar picture size can not exceed 5MB!");
          return false;
        }
        return true;
      case UploadFileType.MEDIA:
        if (rawFile.size / 1024 / 1024 > 50) {
          ElMessage.error("Avatar picture size can not exceed 50MB!");
          return false;
        } else if (
          !rawFile.name.match(/\.ab$/) &&
          !rawFile.name.match(/\.glb$/)
        ) {
          ElMessage.error("File must be AB or GLB format!");
          return false;
        }
        return true;
      default:
        return true;
    }
  }

  handleChange(uploadFile, fileList) {
    const toContinue = this.checkFileType(uploadFile.raw, uploadFile);
    if (!toContinue) {
      this.uploadRef.clearFiles();
      return;
    }
    this.fileSource = uploadFile;
    this.fileList = fileList;
    this.$emit("fileSource", uploadFile);
  }

  handleExceed: UploadProps["onExceed"] = (files) => {
    this.uploadRef.clearFiles();
    const file = files[0] as UploadRawFile;
    file.uid = genFileId();
    this.uploadRef.handleStart(file);
  };
}

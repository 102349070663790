
import { IncomeStatus } from "@/utils/constant.data";
import { requestParams, toJson, excelExporter } from "@/utils/utility";
import { FormInstance } from "element-plus";
import { ref, reactive } from "vue";
import { Options, Vue } from "vue-class-component";
import UserService from "@/api/user.service";

@Options({
  components: {},
  mounted() {
    window.scroll(0, 0);
  },
  created() {
    this.$store.dispatch("getIncome", { PageIndex: 1 });
    this.$store.dispatch("getProducts");
  },
  computed: {
    incomeArray() {
      return this.$store.state.user.incomeInfo;
    },
    myProducts() {
      return this.$store.state.product.allProducts;
    },
    incomeRecordTotalCount() {
      return this.$store.state.user.incomeRecordTotalCount;
    },
    incomeStatus() {
      const arr: any[] = [];
      for (let prop in IncomeStatus) {
        const key = prop;
        const label = IncomeStatus[prop];
        arr.push({ key: key, label: label });
      }
      return arr;
    },
  },
})
export default class CommissionView extends Vue {
  ruleFormRef = ref<FormInstance>();
  ruleForm = reactive({
    name: "",
    date: undefined,
    status: "",
  });
  searchCriteria;
  readonly Content = Content;
  dateLocalStringFormatter = (row: any, column: any, cellValue: any) => {
    return cellValue ? new Date(cellValue).toLocaleDateString() : "-";
  };
  statusFormatter = (row: any, column, cellValue: any) => {
    return IncomeStatus[cellValue ? cellValue : row.status];
  };
  moneyFormatter = (row: any, column: any, cellValue: any) => {
    return `${cellValue.toFixed(2)} USD`;
  };

  incomeFormatter = (row: any, column: any, cellValue: any) => {
    return `${cellValue.toFixed(2)} ${row?.income?.currency.toUpperCase()}`;
  };

  feedFormatter = (feedsArray: Array<any>, feeType: Array<string>) => {
    let feedInfo;
    feeType.forEach((type) => {
      if (!feedInfo) {
        feedInfo = feedsArray.find((item) => item.feeType === type);
      }
    });
    return feedInfo
      ? `${feedInfo.fee} ${feedInfo.currency.toUpperCase()}`
      : `-`;
  };

  disabledDate = (time: Date) => {
    return time.getTime() > Date.now();
  };

  handleCurrentChange(val: number) {
    this.$store.dispatch("getIncome", {
      ...this.searchCriteria,
      PageIndex: val,
    });
  }

  submitForm() {
    const formVal = toJson(this.ruleForm);
    let obj = {
      Name: formVal?.name,
      PurchaseDateFrom: formVal?.date ? formVal?.date[0] : null,
      PurchaseDateTo: formVal?.date ? formVal?.date[1] : null,
      Status: formVal?.status,
    };
    this.searchCriteria = { ...requestParams(obj) };
    this.$store.dispatch("getIncome", obj);
  }

  buildExcel() {
    UserService.getIncome({ PageSize: -1 }).then((res: any) => {
      if (res.totalCount === 0) {
        return;
      }
      const array = res.data.map((item, index) => {
        return {
          index: index + 1,
          name: item.payment.productPreview.name,
          transactionId: item.payment.transactionId,
          purchasedAt: this.dateLocalStringFormatter(
            null,
            null,
            item.payment.purchasedAt
          ),
          benefit: this.incomeFormatter(item, null, item.income.money),
          totalPrice: this.moneyFormatter(null, null, item.payment.totalPrice),
          status: this.statusFormatter(null, null, item.status),
        };
      });
      const header = [
        "#",
        "Project Name",
        "SKU No.",
        "Purchased Date",
        "Selling Price",
        "Your Commission",
        "Status",
      ];
      excelExporter(
        array,
        header,
        "Sales Report",
        [2, 10, 10, 10, 10, 10, 10, 10]
      );
    });
  }
}

const Content = [
  {
    iconName: "hand-holding-dollar",
    title: "Your Finances",
    text: `MMITA commission: MMITA charges a commission based on the partnership agreement and the type of
asset/games being sold by the partner. For details refer to the detailed partner T&Cs. The payment received
by the partner on their stripe account includes deductions from the payment channel, Stripe and MMITA.`,
  },
  {
    iconName: "sack-dollar",

    title: `Payment Schedule`,
    text: `As with any web/platform payment operations, there is a typical delay between the time of payment by the
end user and the funds being credited into Stripe/bank accounts. Hence, we will be providing payments
between 60-90 days after the payment has been received. For instance, if the payment is made by the user
between May 1st and 31st, MMITA will make a payment to the partner on July 31st. Payments will only be
made on the last day of the month. Please note that the payments will be initiated from Hong Kong, hence
there may be delays for international payments.`,
  },
  {
    iconName: "money-bill-wave",
    title: `Payment Currency`,
    text: `All assets and games sold on the MMITA marketplace will be listed in US dollars. Partners are requested to
set the currency on their respective Stripe accounts accordingly.`,
  },
  {
    iconName: "sack-xmark",
    title: `Refund Policy`,
    text: `MMITA does not provide refunds for any digital assets sold through its marketplace. In case the user has
concerns, he/she may reach out to us at contact@mmita.com`,
  },
];

import { ElLoading } from "element-plus";
import SubscriptionService from "@/api/subscription.service";

const openFullScreen2 = () => {
  return ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
};
const subscription = {
  state: {},
  mutations: {},
  actions: {
    getSubscription(context, data) {
      const loading = openFullScreen2();
      return SubscriptionService.getSubscription(data)
        .then((res) => {
          return res;
        })
        .catch((imError) => {
          console.log(imError);
        })
        .finally(() => {
          loading.close();
        });
    },
    getStripePayVPSUrl(context) {
      const loading = openFullScreen2();
      return SubscriptionService.getStripePayVPSUrl()
        .then((res) => {
          return res;
        })
        .catch((imError) => {
          console.log(imError);
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};

export default subscription;


import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { ProductStatus } from "@/utils/constant.data";
import ManageWhiteListView from "@/components/view/dialog/ManageWhiteList.vue";
import MitDialog from "@/components/ui-library/MitDialog.vue";

@Options({
  components: {
    "white-list": ManageWhiteListView,
    "mit-dialog": MitDialog,
  },
  computed: {
    firstName() {
      return this.store.state.user.userProfile.firstName;
    },
    shownList() {
      return this.menu.map((item) => {
        return {
          ...item,
          data: this.shownPageData(this[item.key], item.currentPageNum),
        };
      });
    },
    connectedToStripe() {
      return this.store.state.user?.userProfile?.connectedToStripe;
    },
  },
  mounted() {
    window.scroll(0, 0);
    this.store.dispatch("getProducts").then((res) => {
      this.submissionList = res.data.map((item) => {
        return { ...item, statusStr: ProductStatus[item.status] };
      });
    });
    this.store.dispatch("getModels").then((res) => {
      this.modelList = res.data;
    });
  },
})
export default class MyProjectsView extends Vue {
  store = useStore();
  submissionList = [];
  modelList = [];
  dialogVisible = false;
  menu = [
    { name: "AR Game", key: "submissionList", currentPageNum: 1 },
    { name: "3D Model", key: "modelList", currentPageNum: 1 },
  ];

  updateVisible(val) {
    this.dialogVisible = val;
  }
  // 点击取消的事件
  resetPopupData() {
    this.dialogVisible = false;
  }

  navigateTo(item) {
    switch (item) {
      case "start-submission":
        this.$router.push({ name: "Start Submission" });
        break;
      case "tester":
        this.$router.push({ path: `/developers/my-project/${item}` });
        break;
      case "stripe":
        window.open("https://dashboard.stripe.com/", "_blank");
        break;
      default:
        this.$router.push({
          name: "Detail",
          query: { name: item.name, id: item.id, type: item.type },
        });
        break;
    }
  }
  changePage(pageNumber, key) {
    this.menu.find((item) => {
      if (item.key === key) {
        item.currentPageNum = pageNumber;
      }
    });
  }
  shownPageData(source, pageNum, pageSize = 8) {
    return source.slice((pageNum - 1) * pageSize, pageNum * pageSize);
  }
}


import { Options, Vue } from "vue-class-component";
import MitDialogVue from "../../ui-library/MitDialog.vue";

@Options({
  components: {
    "mit-dialog": MitDialogVue,
  },
  props: {
    dialogVisibled: Boolean,
    content: String,
    btnName: String,
  },
})
export default class DeleteDialog extends Vue {
  dialogVisible;

  cancel() {
    this.$emit("cancel");
  }

  deleteItem() {
    this.$emit("delete");
  }

  // updateVisible(val) {
  //   this.dialogVisible = val;
  // }
  // // 点击取消的事件
  // resetPopupData() {
  //   this.dialogVisible = false;
  // }

  // deleteItem(){

  // }
}


import { Options, Vue } from "vue-class-component";
import ContactUsForm from "@/components/view/ContactUsForm.vue";

@Options({
  components: {
    ContactUsForm,
  },
  mounted() {
    window.scroll(0, 0);
  },
  computed: {
    isSubscribed() {
      return this.$store.state.user?.userProfile?.isSubscribed;
    },
    pricePermonthToString() {
      return this.$store.state.global.setting.pricePermonthToString;
    },
    isCN() {
      return process.env.NODE_ENV === "cn-development";
    },
  },
})
export default class CreatorView extends Vue {
  creatorIntroduces = CreatorIntroduces;
  navigate(isSubscribed) {
    this.$router.push(isSubscribed ? "/vps-placement" : "/vps");
  }
}
const CreatorIntroduces = [
  {
    iconName: "image",
    color: "#5F5FFF",
    title: "Images",
    des: `Adv poster, art pieces, your photos`,
  },
  {
    iconName: "video",
    color: "#F3BA2F",
    title: "Videos",
    des: `Adv Video, movie Trailer, short movie`,
  },
  {
    iconName: "cube",
    color: "#00C3B7",
    title: "3D Assets",
    des: `Our preset 3D file or your approved 3D file`,
  },
  {
    iconName: "vr-cardboard",
    color: "#FF5851",
    title: "Special Utilities",
    des: `Vertical scrolling image banner, google review banner`,
  },
];


import { Options, Vue } from "vue-class-component";
import { MAIN_WEBSITE_URL } from "../../environment";

@Options({
  mounted() {
    window.scroll(0, 0);
  },
})
export default class HomeView extends Vue {
  navigateTo(name) {
    this.$router.push({ name: name });
  }
  openTab(ev) {
    const iosLink = "https://apps.apple.com/hk/app/mmita/id1522417329";
    const androidLink =
      "https://play.google.com/store/apps/details?id=com.Bamb.MMITA";
    switch (ev) {
      case "ios":
        window.open(iosLink);
        break;
      case "andriod":
        window.open(androidLink);
        break;
      case "MMITA":
        window.open(MAIN_WEBSITE_URL);
        break;
    }
  }
}

import { ElLoading, ElMessage } from "element-plus";
import StripeService from "@/api/stripe.service";

const openFullScreen2 = () => {
  return ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
};
const stripe = {
  state: {
    stripeUrlInfo: [],
  },
  mutations: {
    setStripeUrl(state, data) {
      state.stripeUrlInfo = data;
    },
  },
  actions: {
    sendEmail(context, data) {
      const loading = openFullScreen2();
      return StripeService.sendEmail(data)
        .then((res) => {
          ElMessage.success(
            "Stripe connection link has been sent to your email."
          );
          return res;
        })
        .catch((imError) => {
          console.log(imError);
        })
        .finally(() => {
          loading.close();
        });
    },

    confirm(context, data) {
      const loading = openFullScreen2();
      return StripeService.confirm(data)
        .then((res) => {
          ElMessage.success("Stripe connect successfully.");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    getStripeUrl(context) {
      const loading = openFullScreen2();
      return StripeService.getStripeUrl()
        .then((res) => {
          context.commit("setStripeUrl", res);
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};

export default stripe;

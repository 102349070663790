
import { Options, Vue } from "vue-class-component";
import * as signalR from "@microsoft/signalr";
import { reactive, ref } from "vue";
import { checkEmail } from "@/utils/validation";
import { toJson } from "@/utils/utility";
import { FormInstance } from "element-plus";
import { useStore } from "vuex";
import { GAME_URL } from "../../../../environment";
import CreateGameView from "@/components/view/test-staging/CreateGame.vue";
import ResultView from "@/components/view/test-staging/ResultView.vue";
import JoinLeaveGameView from "@/components/view/test-staging/JoinLeaveGame.vue";
import GameInformation from "@/components/view/test-staging/GameInformation.vue";
import GameControllerView from "@/components/view/test-staging/GameController.vue";
import { SignalRGame, GameResult } from "@/model/game.model";
import { SignalRGameAction } from "@/utils/constant.data";
import { ElMessage, ElLoading } from "element-plus";
import SetAchieveGoalInRound from "@/components/view/test-staging/SetAchieveGoalInRound.vue";
import SetGameInfoView from "@/components/view/test-staging/SetGameInfo.vue";

@Options({
  components: {
    CreateGameView,
    ResultView,
    JoinLeaveGameView,
    GameInformation,
    GameControllerView,
    SetAchieveGoalInRound,
    SetGameInfoView,
  },
  provide() {
    return { clear: this.clear };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
})
export default class TestStagingView extends Vue {
  openFullScreen2 = () => {
    return ElLoading.service({
      lock: true,
      text: "Loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
  };
  store = useStore();
  ruleFormRef = ref<FormInstance>();
  ruleForm = reactive({
    password: "",
    email: "",
  });
  rules = reactive({
    password: [
      { required: true, message: "Please input password", trigger: "blur" },
    ],
    email: [{ validator: checkEmail, trigger: "blur" }],
  });
  connection;
  gameId = ref();
  productVersion = ref();
  gameType = ref();
  result: Array<any> = [];
  private getTime = () => {
    var today = new Date();
    return (
      today.toLocaleTimeString() +
      "." +
      today.getMilliseconds().toString().padEnd(3, "0")
    );
  };
  logined = false;
  submitForm(formEl: FormInstance | undefined) {
    if (!formEl) return;
    formEl.validate((valid) => {
      if (valid) {
        const formVal = toJson(this["ruleForm"]);
        this.store.dispatch("gameAccountLogin", formVal).then((res) => {
          this.connetSignalR(res.accessToken);
        });
      } else {
        console.log("error submit!");
        return false;
      }
    });
  }
  connetSignalR(token) {
    this.openFullScreen2();
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${GAME_URL}/socket/Gaming?version=1.17`, {
        accessTokenFactory: () => token.replace("Bearer ", ""),
      })
      .build();
    this.connection.keepAliveIntervalInMilliseconds = 15000;
    this.connection
      .start()
      .then(() => {
        this.logined = true;
        const res: GameResult = {
          time: this.getTime(),
          succeeded: true,
          data: "Connect success...",
          actionName: "Connect",
          msgSate: "succeeded",
        };
        this.result.push(res);
        this.openFullScreen2().close();
        ElMessage.success("Connected");
      })
      .catch((err) => {
        this.openFullScreen2().close();
        ElMessage.error(err.toString());
      });
    this.connection.on("ReceiveMessage", (data) => {
      const res = this.getResJson(data, "ReceiveMessage", true);
      this.result.push(res);
    });

    this.connection.on("EventMessage", (data) => {
      const res = this.getResJson(data, "EventMessage", true);
      this.result.push(res);
    });
  }

  disconnect() {
    this.openFullScreen2();

    this.connection
      .stop()
      .then(() => {
        this.logined = false;
        const res: GameResult = {
          time: this.getTime(),
          succeeded: true,
          data: "Disconnected",
          actionName: "Disconnected",
          msgSate: "succeeded",
        };
        this.result.push(res);
        this.openFullScreen2().close();
        ElMessage.success("Disconnected");
      })
      .catch((err) => {
        this.openFullScreen2().close();
        ElMessage.error(err.toString());
      });
  }

  private getResJson(d, actionName, info = false) {
    const data = JSON.parse(d);
    let state;
    if (info) {
      state = "info";
    } else if (data.succeeded) {
      state = "succeeded";
    } else {
      state = "error";
    }
    const result: GameResult = {
      actionName: actionName,
      succeeded: data.succeeded,
      msgSate: state,
      time: this.getTime(),
      data:
        info || data.succeeded
          ? data.jsonData === ""
            ? ""
            : JSON.parse(data.jsonData)
          : data.error.message,
    };
    return result;
  }

  triggerCreateGame(ev) {
    this.openFullScreen2();
    const connectionInvoke =
      ev.actionName === SignalRGameAction.CreateGame
        ? this.connection.invoke(ev.actionName, ev.arId, ev.baseInfo)
        : this.connection.invoke(ev.actionName, ev.gameId, ev.baseInfo);

    connectionInvoke
      .then((data) => {
        const res = this.getResJson(data, ev.actionName);
        this.result.push(res);
        this.openFullScreen2().close();
        if (res.succeeded) {
          if (ev.actionName === SignalRGameAction.CreateGame) {
            this.gameId = res?.data?.id;
            this.productVersion = res?.data?.productVersion;
            this.gameType = res?.data?.gameType;
          }
          ElMessage.success(`${ev.actionName} successfully`);
        } else {
          ElMessage.error(res.data);
        }
      })
      .catch((err) => {
        this.openFullScreen2().close();
        ElMessage.error(err.toString());
      });
  }

  trigger(ev: SignalRGame) {
    this.openFullScreen2();
    let connectionInvoke;
    switch (ev.actionName) {
      case SignalRGameAction.SetAchieveGoalInRound:
        connectionInvoke = this.connection.invoke(
          ev.actionName,
          ev.gameId,
          ev.prop.key,
          ev.prop.value
        );
        break;
      case SignalRGameAction.StartGameV1:
      case SignalRGameAction.SetMyGameInfo:
        connectionInvoke = this.connection.invoke(
          ev.actionName,
          ev.gameId,
          ev.prop
        );
        break;
      default:
        connectionInvoke = ev.gameId
          ? this.connection.invoke(ev.actionName, ev.gameId)
          : this.connection.invoke(ev.actionName);
        break;
    }

    connectionInvoke
      .then((data) => {
        const res = this.getResJson(data, ev.actionName);
        this.result.push(res);
        this.openFullScreen2().close();
        res.succeeded
          ? ElMessage.success(`${ev.actionName} successfully`)
          : ElMessage.error(res.data);
      })
      .catch((err) => {
        this.openFullScreen2().close();
        ElMessage.error(err.toString());
      });
  }
  viewDoc() {
    window.open(`${GAME_URL}/documentation.html`);
  }
  clear() {
    this.result = [];
  }
}

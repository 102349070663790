import Request from "../utils/request";

let service = {
  createProduct: (formParams) => {
    return Request({
      url: "/api/Product",
      method: "post",
      data: formParams,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
  getProduct: () => {
    return Request({
      url: "/api/Product",
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
      params: {
        PageSize: -1,
      },
    });
  },
  getProductItem: (id) => {
    return Request({
      url: `/api/Product/${id}`,
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },

  getProductVersions: (id) => {
    return Request({
      url: `/api/Product/Versions/${id}`,
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
  deleteProduct: (id) => {
    return Request({
      url: `/api/Product/${id}`,
      method: "delete",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },

  updateProduct: (formParams, id) => {
    return Request({
      url: `/api/Product/${id}`,
      method: "put",
      data: formParams,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },

  takeDownVersion: (productId) => {
    return Request({
      url: `/api/Product/Version/TakeDown/${productId}`,
      method: "put",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },

  submitVersion: (productId, vId) => {
    return Request({
      url: `/api/Product/Version/Submit/${productId}/${vId}`,
      method: "put",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },

  addVersion: (formParams) => {
    return Request({
      url: "/api/Product/Version",
      method: "post",
      data: formParams,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },

  updateVersion: (formParams, id) => {
    return Request({
      url: `/api/Product/Version/${id}`,
      method: "put",
      data: formParams,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },

  getVersion: (id) => {
    return Request({
      url: `/api/Product/Version/${id}`,
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
  getRecentProduct: () => {
    return Request({
      url: `/api/Product/Recent`,
      method: "get",
    });
  },
};

export default service;

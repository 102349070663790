
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import AddTesterView from "@/components/view/dialog/AddTester.vue";
import MitDialogVue from "@/components/ui-library/MitDialog.vue";
@Options({
  components: {
    "add-tester": AddTesterView,
    "mit-dialog": MitDialogVue,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    whiteList() {
      return this.store.state.whiteList.whiteList.map((item) => {
        return {
          ...item,
          email: item.user.email,
          fullName: `${item.user.fullName}`,
          avatar: item.user.profileUrl,
        };
      });
    },
  },
})
export default class ManageTesterView extends Vue {
  store = useStore();
  dialogVisible = false;
  created() {
    this.loadWhiteList();
  }

  remove(rowData) {
    const id = rowData.id;
    this.store.dispatch("removeWhiteListItem", id);
  }

  loadWhiteList() {
    this.store.dispatch("getWhiteList");
  }

  updateVisible(val) {
    this.dialogVisible = val;
  }
  // 点击取消的事件
  resetPopupData() {
    this.dialogVisible = false;
  }
}

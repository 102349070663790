
import { reactive, ref } from "vue";
import type { FormInstance } from "element-plus";
import { Vue } from "vue-class-component";
import { toJson } from "@/utils/utility";

import { useStore } from "vuex";
import router from "@/router";
import { checkEmail } from "@/utils/validation";

export default class LoginForm extends Vue {
  ruleFormRef = ref<FormInstance>();
  store = useStore();
  router = router;
  ruleForm = reactive({
    email: "",
    password: "",
  });

  rules = reactive({
    password: [
      { required: true, message: "Please input password", trigger: "blur" },
    ],
    email: [{ validator: checkEmail, trigger: "blur" }],
  });

  submitForm(formEl: FormInstance | undefined) {
    if (!formEl) return;
    formEl.validate((valid) => {
      if (valid) {
        const formVal = toJson(this.ruleForm);
        this.store.dispatch("login", formVal).then((res) => {
          if (this.$route?.query?.redirect) {
            let redirect = this.$route.query.redirect as string;
            if (redirect === "/vps" && res.isSubscribed) {
              redirect = "/vps-placement";
            }
            this.router.push({ path: redirect });
          } else {
            this.router.push({ path: "/developers/my-project" });
          }
        });
      } else {
        console.log("error submit!");
        return false;
      }
    });
  }
  navigateTo() {
    this.$router.push({ path: "/forgot-password" });
  }
}

<template>
  <div class="banner-wrapper main-bg">
    <h1 class="main-content">
      {{ currentView }}
    </h1>
    <div class="banner-nav">
      <router-link class="menu-title" to="/">Home</router-link>/
      <span v-for="(item, index) in routerList" :key="index">
        <template v-if="index !== routerList.length - 1">
          <span class="menu-title" @click="navigateTo(item.path)">{{
            item.name
          }}</span
          >/
        </template>
      </span>
      &ensp;
      {{ currentView }}
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentView() {
      switch (this.$route.name) {
        case "Detail":
          return this.$route.query.name;
      }
      return this.$route.name;
    },
    routerList() {
      return this.$route.matched;
    },
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.banner-wrapper {
  padding-top: 90px;
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .menu-title {
    color: #fff;
    text-decoration-line: none;
    padding: 0 10px;
    font-weight: bold;
    cursor: pointer;
  }
  .banner-nav {
    color: #c9b5e5;
    padding-bottom: 30px;
  }
}
</style>

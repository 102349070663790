import Request from "../utils/request";

let service = {
  getIncome: (params) => {
    return Request({
      url: "/api/User/Income/Query",
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
      params: params,
    });
  },
};

export default service;


import { ButtonType } from "@/utils/constant.data";
import { throttle } from "lodash";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    name: String,
    size: String,
    btnStyle: String,
    disabled: Boolean,
    type: String,
  },
  emits: ["onClick"],
  methods: {
    onClick: throttle(function (event) {
      this.$emit("onClick");
    }, 800),
  },
})
export default class MitButton extends Vue {
  readonly ButtonType = ButtonType;
}

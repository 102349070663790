import Request from "../utils/request";
const prefixUrl = "/api/Stage";
let service = {
  getStages: (params) => {
    return Request({
      url: `${prefixUrl}/Query`,
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
      params: { ...params, pageSize: 10 },
    });
  },

  postStage: (formParams) => {
    return Request({
      url: `${prefixUrl}`,
      method: "post",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
      data: formParams,
    });
  },

  postVCStage: (formParams) => {
    return Request({
      url: `${prefixUrl}/VerticalCarousel`,
      method: "post",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
      data: formParams,
    });
  },

  deleteStage: (id) => {
    return Request({
      url: `${prefixUrl}/${id}`,
      method: "delete",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },

  updateStage: (formParams, id) => {
    return Request({
      url: `${prefixUrl}/${id}`,
      method: "put",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
      data: formParams,
    });
  },

  updateVCStage: (formParams, id) => {
    return Request({
      url: `${prefixUrl}/VerticalCarousel/${id}`,
      method: "put",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
      data: formParams,
    });
  },
};

export default service;

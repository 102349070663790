import Request from "../utils/request";

let service = {
  getWhiteList: () => {
    return Request({
      url: "/api/WhiteList",
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },

  removeWhiteListItem: (id) => {
    return Request({
      url: `/api/WhiteList/${id}`,
      method: "delete",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },

  addWhiteList: (formParams) => {
    return Request({
      url: "/api/WhiteList",
      method: "post",
      data: formParams,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },

  searchWhiteListItem(email) {
    return Request({
      url: `/api/WhiteList/Search`,
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
      params: {
        email: email,
      },
    });
  },
};

export default service;

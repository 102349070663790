
import { Options, Vue } from "vue-class-component";
import MitTerminal from "@/components/ui-library/MitTerminal.vue";

@Options({
  props: {
    result: Array,
  },
  computed: {
    command() {
      return this.result;
    },
  },
  components: {
    "mit-terminal": MitTerminal,
  },
  watch: {
    result(newVal) {
      console.log(newVal);
    },
  },
})
export default class ResultView extends Vue {}

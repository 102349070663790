
import { Options, Vue } from "vue-class-component";
import ContactUsForm from "@/components/view/ContactUsForm.vue";

@Options({
  components: {
    ContactUsForm,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
})
export default class BusinessPartnerView extends Vue {}

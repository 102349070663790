let PARTNER_API = "";
let FILE_API = "";
let GAME_URL = "";
let MAIN_WEBSITE_URL = "";
let MAIN_API = "";
let PARTNER_WESITE_URL = "";

switch (process.env.NODE_ENV) {
  case "development":
    PARTNER_API = "https://apipartnerdev.mmita.io";
    FILE_API = "https://filedev.mmita.io";
    GAME_URL = "https://gamedev.mmita.io";
    MAIN_WEBSITE_URL = "https://dev.mmita.io";
    MAIN_API = "https://apidev.mmita.io";
    PARTNER_WESITE_URL = "https://partnerdev.mmita.io";
    break;
  case "production":
    PARTNER_API = "https://apipartner.mmita.com";
    FILE_API = "https://file.mmita.com";
    GAME_URL = "https://game.mmita.com";
    MAIN_WEBSITE_URL = "https://mmita.com";
    MAIN_API = "https://api.mmita.com";
    PARTNER_WESITE_URL = "https://partner.mmita.com";
    break;
  case "cn-development":
    PARTNER_API = "https://cnmmita-partner-dev.azurewebsites.net";
    FILE_API = "https://cnmmita-file-dev.azurewebsites.net";
    GAME_URL = "https://cnmmita-game-dev.azurewebsites.net";
    MAIN_WEBSITE_URL =
      "https://cnmmitaswebmaindev.z2.web.core.chinacloudapi.cn";
    MAIN_API = "https://cnmmita-api-main-dev.azurewebsites.net";
    PARTNER_WESITE_URL =
      "https://cnmmitaswebpartnerdev.z2.web.core.chinacloudapi.cn";
    break;
  default:
    PARTNER_API = "https://apipartnerdev.mmita.io";
    FILE_API = "https://filedev.mmita.io";
    GAME_URL = "https://gamedev.mmita.io";
    MAIN_WEBSITE_URL = "https://dev.mmita.io";
    MAIN_API = "https://apidev.mmita.io";
    PARTNER_WESITE_URL = "https://partnerdev.mmita.io";
    break;
}
export {
  PARTNER_API,
  FILE_API,
  GAME_URL,
  MAIN_WEBSITE_URL,
  MAIN_API,
  PARTNER_WESITE_URL,
};


import { InfiniteArray } from "@/model/infiniteArray.model";
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";

@Options({
  data() {
    return {
      requirementArray: RequirementArray,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("getRecentProduct").then((res: InfiniteArray) => {
      this.productArray = res.data;
      this.currentPageItems = this.productArray.slice(0, 4);
    });
  },
  computed: {
    gameSdkFileUrl() {
      return this.$store.state.global.setting.gameSdkFileUrl;
    },
    isLogin() {
      return this.$store.state.user.isLogin;
    },
  },
})
export default class DevelopersView extends Vue {
  productArray;
  currentPageItems = ref();
  naviageTo(name) {
    this.$router.push({ name: name });
  }
  handleCurrentChange(val: number) {
    this.currentPageItems = this.productArray.slice((val - 1) * 4, 4 * val);
  }
  showGameSDK(gameSdkFileUrl) {
    window.open(gameSdkFileUrl);
  }
}

const RequirementArray = [
  {
    iconName: "lock",
    color: "#5F5FFF",
    title: "Confidential",
    des: `Your submissions will be kept confidential with MMITA. Information will be deleted if your submission doesn't make it to the MMITA marketplace`,
  },
  {
    iconName: "lightbulb",
    color: "#F3BA2F",
    title: "Fast Solutions",
    des: `We aim to process your submission at the earliest to provide our end users with your creative asset`,
  },
  {
    iconName: "user-tie",
    color: "#00C3B7",
    title: "Customer Cares",
    des: `We will provide support and reach out to you if we've questions or concerns about your submissions. Every developer can reach out to us at <a href="mailto:contact@mmita.com" target="_blank">contact@mmita.com</a>`,
  },
  {
    iconName: "file-contract",
    color: "#FF5851",
    title: "Legal Content",
    des: `Our policies have been stipulated in the terms & conditions laid out in this link`,
  },
];


import { formInvalidHandler, toJson } from "@/utils/utility";
import { ElMessage, FormInstance, UploadUserFile } from "element-plus";
import { ref, reactive } from "vue";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import MitUploaderVue from "@/components/ui-library/MitUploader.vue";
import { UploadItem } from "@/model/upload.model";
import { ThreeModelSubmission } from "@/model/submission.model";
import router from "@/router";
import { UploadFileType } from "@/utils/constant.data";
import { checkNumNonnegative } from "@/utils/validation";
import MitTag from "@/components/ui-library/MitTag.vue";

@Options({
  components: {
    "mit-uploader": MitUploaderVue,
    MitTag,
  },
  computed: {
    modelCategoryList() {
      return this.store.state.model.modelCategoryList;
    },
  },

  mounted() {
    window.scrollTo(0, 0);
    this.store.dispatch("getModelCategoryList");

    if (!this.$route.query.id) {
      this.rendered = true;
      return;
    }
    this.store.dispatch("getModelItem", this.$route.query.id).then((res) => {
      this.id = res.id;
      this.ruleForm.name = res.name;
      this.ruleForm.description = res.description;
      this.ruleForm.avatar = ref<UploadUserFile[]>([
        { name: res.name, url: res.thumbnail.url },
      ]);
      if (res.coverThumbnail) {
        this.ruleForm.cover = ref<UploadUserFile[]>([
          { name: "cover", url: res.coverThumbnail.url },
        ]);
      }
      this.ruleForm.price = res.price;
      this.ruleForm.iconUrl = res.icon.url;
      this.ruleForm.thumbnailUrl = res.thumbnail.url;
      this.ruleForm.smallThumbnailUrl = res.smallThumbnail.url;
      this.ruleForm.coverUrl = res?.coverThumbnail?.url;
      this.ruleForm.categoryId = res?.category?.categoryId;
      this.ruleForm.tags = res?.tags.map((item) => {
        return item.name;
      });
      this.rendered = true;
    });
  },
})
export default class SubmissionView extends Vue {
  readonly UploadFileType = UploadFileType;
  id = ref();
  btnName = ref();
  ruleFormRef = ref<FormInstance>();
  store = useStore();
  ruleForm = reactive({
    name: "",
    description: "",
    price: "",
    avatar: ref(), // for show
    cover: ref(),

    //real data
    iconUrl: "",
    thumbnailUrl: "",
    smallThumbnailUrl: "",
    coverUrl: "",
    categoryId: "",
    tags: [],
  });
  // upload file source
  avatar = ref();
  router = router;
  editMode = false;
  coverSource = ref();
  rendered = false;

  created() {
    const id = this.$route.query.id;
    if (id) {
      this.editMode = true;
      this.btnName = ref("Update 3D Model");
    } else {
      this.btnName = ref("Create 3D Model");
    }
  }

  rules = reactive({
    price: [{ validator: checkNumNonnegative, trigger: "blur" }],
    name: [
      { required: true, message: "Please input project name", trigger: "blur" },
    ],
    categoryId: [
      { required: true, message: "Please input category", trigger: "blur" },
    ],
    avatar: [
      { required: true, message: "Please upload thumbnail", trigger: "change" },
    ],
    cover: [
      {
        required: true,
        message: "Please upload cover image",
        trigger: "blur",
      },
    ],
  });

  submitForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    formEl.validate((valid, fields) => {
      if (valid) {
        this.submit();
      } else {
        formInvalidHandler(fields);
        return false;
      }
    });
  };

  submit() {
    const formVal = toJson(this.ruleForm);
    const fileArray: any[] = [];
    this.coverSource &&
      fileArray.push(new UploadItem("coverThumbnailUrl", this.coverSource));
    this.avatar && fileArray.push(new UploadItem("avatar", this.avatar));

    if (fileArray.length > 0) {
      this.store.dispatch("multiUpload", fileArray).then((res: Array<any>) => {
        const subData = new ThreeModelSubmission(
          formVal,
          res.find((item) => item.name === "avatar"),
          res.find((item) => item.name === "coverThumbnailUrl")
        );
        if (this.editMode) {
          this.store
            .dispatch("updateModel", { body: subData, id: this.id })
            .then((data) => {
              this.router.push({
                name: "Detail",
                query: {
                  name: data.name,
                  id: this.id as any,
                  type: "3D Model",
                },
              });
            });
        } else {
          this.store.dispatch("createModel", subData).then((data) => {
            this.router.push({
              name: "Detail",
              query: { name: data.name, id: data.id as any, type: "3D Model" },
            });
          });
        }
      });
    } else if (this.editMode) {
      const updateData = new ThreeModelSubmission(formVal);
      this.store
        .dispatch("updateModel", { body: updateData, id: this.id })
        .then((data) => {
          this.router.push({
            name: "Detail",
            query: { name: data.name, id: this.id as any, type: "3D Model" },
          });
        });
    }
  }

  fileChange(ev, fileType: string) {
    this[fileType] = ev.raw;
    setTimeout(() => {
      (this.ruleFormRef as any).validateField(
        fileType === "avatar" ? "avatar" : "cover"
      );
    }, 400);
  }

  updateTags(value) {
    this.ruleForm.tags = value;
  }
}


import MitUploader from "@/components/ui-library/MitUploader.vue";
import { VersionModel } from "@/model/submission.model";
import { UploadItem } from "@/model/upload.model";
import router from "@/router";
import { UploadFileType } from "@/utils/constant.data";
import { ElMessage, FormInstance, UploadUserFile } from "element-plus";
import { ref } from "vue";
import { Options, Vue } from "vue-class-component";
import { reactive } from "vue-demi";
import { useStore } from "vuex";
import { formInvalidHandler } from "@/utils/utility";

@Options({
  components: {
    "mit-uploader": MitUploader,
  },
  created() {
    this.isGame = this.$route.query.type === "AR Game";
  },
  computed: {
    mainInfo() {
      return this.isGame
        ? this.store.state.product.productInfo
        : this.store.state.model.productInfo;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    const id = this.$route.query.prodId;
    this.versionId = this.$route.query.vId as any;
    this.getMainInfo(id);
    this.versionId && this.getVersionInfo(this.versionId);
  },
})
export default class VersionView extends Vue {
  readonly UploadFileType = UploadFileType;
  ruleFormRef = ref<FormInstance>();
  store = useStore();
  router = router;
  ruleForm = reactive({
    iosFile: ref(),
    androidFile: ref(),
    visionOSFile: ref(),
    macOSFile: ref(),
    updateLog: "",

    // for data
    iosAssetUrl: "",
    androidAssetUrl: "",
    visionOSAssetUrl: "",
    macOSAssetUrl: "",
  });
  versionNumber = ref();

  // upload file source
  iosFileSource = ref();
  androidFileSource = ref();
  visionOSFileSource = ref();
  macOSFileSource = ref();

  fileList = [
    {
      ruleFormKey: "iosFile",
      label: "IOS File",
      fileSource: "iosFileSource",
      dataKey: "iosAssetUrl",
      required: true,
    },
    {
      ruleFormKey: "androidFile",
      label: "Android File",
      fileSource: "androidFileSource",
      dataKey: "androidAssetUrl",
      required: true,
    },
    {
      ruleFormKey: "visionOSFile",
      label: "VisionOS File",
      fileSource: "visionOSFileSource",
      dataKey: "visionOSAssetUrl",
      required: false,
    },
    {
      ruleFormKey: "macOSFile",
      label: "MacOS File",
      fileSource: "macOSFileSource",
      dataKey: "macOSAssetUrl",
      required: false,
    },
  ];

  rules = reactive({
    iosFile: [
      { required: true, message: "Please upload ios file", trigger: "blur" },
    ],
    androidFile: [
      {
        required: true,
        message: "Please upload android file",
        trigger: "blur",
      },
    ],
    updateLog: [
      { required: true, message: "Please input update log", trigger: "blur" },
    ],
  });

  versionInfo;
  versionId = "";
  isGame;
  getMainInfo(id) {
    this.isGame
      ? this.store.dispatch("getProductItem", id)
      : this.store.dispatch("getModelItem", id);
  }

  getVersionInfo(id) {
    this.store
      .dispatch(this.isGame ? "getVersion" : "getModelVersion", id)
      .then((res) => {
        this.versionInfo = res;
        this.ruleForm.updateLog = res.updateLog;
        this.versionNumber = this.isGame
          ? res.productVersion
          : res.modelVersion;
        //   for shown

        this.fileList.forEach((item) => {
          if (res[item.dataKey]) {
            this.ruleForm[item.ruleFormKey] = ref<UploadUserFile[]>([
              { name: item.ruleFormKey, url: res[item.dataKey] },
            ]);
          }
          this.ruleForm[item.dataKey] = res[item.dataKey];
        });
      });
  }

  fileChange(ev, type) {
    this[type] = ev.raw;
    enum ruleField {
      iosFileSource = "iosFile",
      androidFileSource = "androidFile",
      visionOSFileSource = "visionOSFile",
      macOSFileSource = "macOSFile",
    }

    setTimeout(() => {
      (this.ruleFormRef as any).validateField(ruleField[type]);
    }, 400);
  }

  submit(formEl: FormInstance | undefined, mainInfo) {
    if (!formEl) return;
    formEl.validate((valid, fields) => {
      if (valid) {
        this.submitForm(mainInfo);
      } else {
        formInvalidHandler(fields);
        return false;
      }
    });
  }

  submitForm(mainInfo) {
    const fileArray: any[] = [];
    this.fileList.forEach((item) => {
      this[item.fileSource] &&
        fileArray.push(new UploadItem(item.dataKey, this[item.fileSource]));
    });
    if (fileArray.length > 0) {
      this.store.dispatch("multiUpload", fileArray).then((res) => {
        const fileUrl = {};
        res.forEach((item) => {
          fileUrl[item.name] = item.origin;
        });
        this.updateVersion(new VersionModel(this.ruleForm, fileUrl), mainInfo);
      });
    } else {
      this.updateVersion(new VersionModel(this.ruleForm), mainInfo);
    }
  }

  updateVersion(data, mainInfo) {
    this.store
      .dispatch(this.isGame ? "updateVersion" : "updateModelVersion", {
        data: data,
        id: this.versionId,
      })
      .then(() => {
        this.router.push({
          name: "Detail",
          query: {
            name: mainInfo.name,
            id: mainInfo.id,
            type: this.$route.query.type,
          },
        });
      });
  }
}

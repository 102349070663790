import AccessService from "@/api/access.service";
import { ElLoading, ElMessage } from "element-plus";
import { RegisterUser } from "../../model/user.model";
import UserService from "@/api/user.service";

const openFullScreen2 = () => {
  return ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
};

const user = {
  state: {
    userProfile: {},
    isLogin: false,
    accessToken: "",
    incomeInfo: [],
    incomeRecordTotalCount: 0,
  },
  getters: {
    accessToken: (state) => state.accessToken,
  },
  mutations: {
    // updateCurrentUserProfile(state, userProfile) {
    //   state.userProfile = userProfile
    // },
    toggleIsLogin(state, isLogin) {
      state.isLogin = typeof isLogin === "undefined" ? !state.isLogin : isLogin;
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    logout(state) {
      Object.assign(state, {
        userProfile: {},
        isLogin: false,
        accessToken: null,
      });
      localStorage.removeItem("platform_accesstoken");
    },
    GET_USER_INFO(state, payload) {
      if (payload.accessToken) {
        state.accessToken = payload.accessToken;
      }
      state.userProfile = payload;
    },

    getIncomeInfo(state, payload) {
      state.incomeInfo = payload.data;
      state.incomeRecordTotalCount = payload.totalCount;
    },
  },
  actions: {
    login(context, data) {
      const loading = openFullScreen2();
      return AccessService.login(data)
        .then((res) => {
          context.commit("toggleIsLogin", true);
          context.commit("GET_USER_INFO", res);
          localStorage.setItem("platform_accesstoken", res.accessToken);
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    gameAccountLogin(context, data) {
      const loading = openFullScreen2();
      return AccessService.gameAccountLogin(data)
        .then((res) => {
          localStorage.setItem("game_accesstoken", res.accessToken);
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    sendRegistrationCode(context, data) {
      const loading = openFullScreen2();
      return AccessService.sendRegistrationCode(data)
        .then((res) => {
          ElMessage.success("Verification code has been setInterval");
        })
        .catch((imError) => {
          console.log(imError);
        })
        .finally(() => {
          loading.close();
        });
    },

    sendResetPasswordCode(context, data) {
      const loading = openFullScreen2();
      return AccessService.sendResetPasswordCode(data)
        .then((res) => {
          ElMessage.success("Verification code has been setInterval");
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          loading.close();
        });
    },
    register(context, data) {
      const loading = openFullScreen2();
      const user = new RegisterUser(data);
      return AccessService.register(user)
        .then((res) => {
          context.commit("toggleIsLogin", true);
          context.commit("GET_USER_INFO", res);
          ElMessage.success("Registered Successfully");
          localStorage.setItem("platform_accesstoken", res.accessToken);
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    getMyProfile(context) {
      const loading = openFullScreen2();
      return AccessService.getMyProfile(context.state.accessToken)
        .then((res) => {
          context.commit("toggleIsLogin", true);
          context.commit("GET_USER_INFO", res);
          return res
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    resetPassword(context, data) {
      const loading = openFullScreen2();
      return AccessService.resetPassword(data)
        .then((res) => {
          ElMessage.success("Reset Successfully");
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          loading.close();
        });
    },
    updateProfile(context, data) {
      const loading = openFullScreen2();
      return AccessService.updateProfile(data)
        .then((res) => {
          ElMessage.success("Update Successfully");
          context.commit("GET_USER_INFO", res);
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          loading.close();
        });
    },

    getIncome(context, data) {
      const loading = openFullScreen2();
      return UserService.getIncome(data)
        .then((res) => {
          context.commit("getIncomeInfo", res);
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};

export default user;

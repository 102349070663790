import Request from "../utils/request";

let service = {
  createModel: (formParams) => {
    return Request({
      url: "/api/Model",
      method: "post",
      data: formParams,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
  updateModel: (formParams, id) => {
    return Request({
      url: `/api/Model/${id}`,
      method: "put",
      data: formParams,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
  getModelItem: (id) => {
    return Request({
      url: `/api/Model/${id}`,
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },

  getModelCategoryList: () => {
    return Request({
      url: `/api/Model/Category/List`,
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
  getModels: () => {
    return Request({
      url: `/api/Model`,
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
      params: {
        PageSize: -1,
      },
    });
  },
  getModelVersions: (id) => {
    return Request({
      url: `/api/Model/Versions/${id}`,
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
  addVersion: (formParams) => {
    return Request({
      url: "/api/Model/Version",
      method: "post",
      data: formParams,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
  getVersion: (id) => {
    return Request({
      url: `/api/Model/Version/${id}`,
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
  updateVersion: (formParams, id) => {
    return Request({
      url: `/api/Model/Version/${id}`,
      method: "put",
      data: formParams,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
  deleteModel: (id) => {
    return Request({
      url: `/api/Model/${id}`,
      method: "delete",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
  submitVersion: (productId, vId) => {
    return Request({
      url: `/api/Model/Version/Submit/${productId}/${vId}`,
      method: "put",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
  takeDownVersion: (productId) => {
    return Request({
      url: `/api/Model/Version/TakeDown/${productId}`,
      method: "put",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
};

export default service;

import { minBy } from "lodash";
import { ElMessage } from "element-plus";
import ExportJsonExcel from "js-export-excel";

export function toJson(val) {
  return JSON.parse(JSON.stringify(val));
}

function scrollToFirstInvalid(invalidArray) {
  const fieldList: any[] = [];
  for (const prop in invalidArray) {
    const top = document.getElementById(prop)?.offsetTop;
    fieldList.push({ offsetTop: top, id: prop });
  }
  const firstInv = minBy(fieldList, function (o) {
    return o.offsetTop;
  });
  document.documentElement.scrollTop = firstInv.offsetTop;
}

export function formInvalidHandler(invalidArray) {
  scrollToFirstInvalid(invalidArray);
  ElMessage.warning("please complete the form");
}

export function requestParams(params) {
  const _parms = {};
  for (const prop in params) {
    if (params[prop]) {
      _parms[prop] = params[prop];
    }
  }
  return JSON.stringify(_parms) == "{}" ? null : _parms;
}

export function excelExporter(
  jsonData: Array<any>,
  header: Array<string>,
  name: string,
  columnWidths: Array<number>
) {
  const option = {};

  option["fileName"] = name;

  option["datas"] = [
    {
      sheetData: jsonData,
      sheetHeader: header,
      columnWidths: columnWidths,
    },
  ];

  const toExcel = new ExportJsonExcel(option); //new
  toExcel.saveExcel();
}

export function toLocaleDateString(dateString?) {
  const date = dateString ? new Date(dateString) : new Date();
  return date.toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

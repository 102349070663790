
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    menuList: Array,
  },
})
export default class MitDropdownMenu extends Vue {}

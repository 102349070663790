import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 导入Bootstrap样式
import "bootstrap/dist/css/bootstrap.css";
// 导入Bootstrap JS文件
import "bootstrap";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "github-markdown-css";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import "highlight.js/styles/stackoverflow-light.css";
import "highlight.js/lib/common";
import hljsVuePlugin from "@highlightjs/vue-plugin";
import i18n from "./utils/language";
import "animate.css";
import MitButtonVue from "./components/ui-library/MitButton.vue";

library.add(fas);

const app = createApp(App)
  .use(store)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon);
app.use(ElementPlus);
app.use(hljsVuePlugin);
app.use(i18n);
app.mount("#app");
app.config.globalProperties.$store = store;
app.component("mit-button", MitButtonVue);
app.directive("highlight", function (el) {
  const blocks = el.querySelectorAll("pre code");
  blocks.forEach((block) => {
    hljs.highlightBlock(block);
  });
});

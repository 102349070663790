import Request from "../utils/request";

let service = {
  sendEmail: (formParams) => {
    return Request({
      url: "/api/Stripe/Connect/SendEmail",
      method: "post",
      data: formParams,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },

  confirm: (formParams) => {
    return Request({
      url: "/api/Stripe/Connect/Confirm",
      method: "post",
      data: formParams,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },

  getStripeUrl: () => {
    return Request({
      url: "/api/Stripe/Connect/Url",
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
    });
  },
};

export default service;

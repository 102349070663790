import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ab551ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tester-info"
}
const _hoisted_2 = { class: "user-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_mit_button = _resolveComponent("mit-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "ruleFormRef",
    model: _ctx.ruleForm,
    "status-icon": "",
    rules: _ctx.rules,
    size: 'large',
    class: "add-version-wrapper"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_form_item, {
          prop: "email",
          class: "input-wrapper"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.ruleForm.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ruleForm.email) = $event)),
              type: "text",
              autocomplete: "off",
              placeholder: "MMITA User Registered Email",
              class: "my-field"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_mit_button, {
          onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.check(_ctx.ruleFormRef))),
          name: 'Check',
          btnStyle: 'blank-btn'
        })
      ]),
      (_ctx.testerInfo?.fullName)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, " UserName: " + _toDisplayString(_ctx.testerInfo.fullName), 1),
            _createVNode(_component_mit_button, {
              onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.add(_ctx.testerInfo.email))),
              name: 'Add Tester'
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}

import { Options, Vue } from "vue-class-component";
import { reactive, ref } from "vue";
import { FormInstance } from "element-plus";
import { SignalRGameAction } from "@/utils/constant.data";
import { SignalRGame } from "@/model/game.model";
@Options({
  props: {
    gameIdSource: String,
  },
  watch: {
    gameIdSource(newVal) {
      this.ruleForm.startGameId = newVal;
      this.ruleForm.endGameId = newVal;
      this.ruleForm.closeGameId = newVal;
      this.ruleForm.viewGameId = newVal;
      this.ruleForm.winGameId = newVal;
      this.ruleForm.loseGameId = newVal;
    },
  },
  data() {
    return {
      SignalRGameAction: SignalRGameAction,
    };
  },
})
export default class GameControllerView extends Vue {
  ruleFormRef = ref<FormInstance>();
  ruleForm = reactive({
    startGameId: "",
    endGameId: "",
    closeGameId: "",
    viewGameId: "",
    winGameId: "",
    loseGameId: "",
    myGameInfo: "",
  });
  rules = reactive({
    startGameId: [
      { required: true, message: "Please input Game ID", trigger: "blur" },
    ],
    endGameId: [
      { required: true, message: "Please input Game ID", trigger: "blur" },
    ],
    closeGameId: [
      { required: true, message: "Please input Game ID", trigger: "blur" },
    ],
    viewGameId: [
      { required: true, message: "Please input Game ID", trigger: "blur" },
    ],
    winGameId: [
      { required: true, message: "Please input Game ID", trigger: "blur" },
    ],
    loseGameId: [
      { required: true, message: "Please input Game ID", trigger: "blur" },
    ],
  });
  submitForm = (formEl: FormInstance | undefined, action, fieldName) => {
    if (!formEl) return;
    formEl.validateField(fieldName, (valid) => {
      if (valid) {
        if (
          action === SignalRGameAction.StartGame &&
          this.ruleForm.myGameInfo
        ) {
          this.$emit(
            "trigger",
            new SignalRGame(
              SignalRGameAction.StartGameV1,
              this.ruleForm[fieldName],
              this.ruleForm.myGameInfo
            )
          );
        } else {
          this.$emit(
            "trigger",
            new SignalRGame(action, this.ruleForm[fieldName])
          );
        }
      } else {
        console.log("error submit!");
        // return false;
      }
    });
  };
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!

  return (_openBlock(), _createBlock(_component_el_dropdown_menu, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (item, index) => {
        return (_openBlock(), _createBlock(_component_el_dropdown_item, {
          key: index,
          command: item
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item), 1)
          ]),
          _: 2
        }, 1032, ["command"]))
      }), 128))
    ]),
    _: 1
  }))
}
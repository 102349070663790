
import { Options, Vue } from "vue-class-component";
import GitHubAdvancedView from "@/views/vps-view/GitHubAdvancedView.vue";
import MitMap from "@/components/ui-library/MitMap.vue";
import MapSearchForm from "@/components/view/map/MapSearchForm.vue";
import MitDialog from "@/components/ui-library/MitDialog.vue";
import { InfiniteArray } from "@/model/infiniteArray.model";
import { ElMessage, FormInstance, UploadUserFile } from "element-plus";
import { ref, reactive } from "vue";
import { toJson } from "@/utils/utility";
import MitUploader from "@/components/ui-library/MitUploader.vue";
import { UploadFileType } from "@/utils/constant.data";
import EventBus from "@/utils/eventBus";
import { QuestionFilled } from "@element-plus/icons-vue";

@Options({
  components: {
    GitHubAdvancedView,
    MitMap,
    MapSearchForm,
    "mit-dialog": MitDialog,
    MitUploader,
    QuestionFilled,
  },
  computed: {
    modelCategoryList() {
      return this.$store.state.model.modelCategoryList;
    },
    isSubscribed() {
      return this.$store.state.user?.userProfile?.isSubscribed;
    },
  },
})
export default class ConfigPlacementView extends Vue {
  readonly UploadFileType = UploadFileType;
  type = "Normal";
  dialogVisible = false;
  categoryType = null;
  shownAsset: any[] = [];
  selectedAsset: any = null;
  ruleFormRef = ref<FormInstance>();
  ruleForm = reactive({
    height: "",
    scale: "",
    isGeospatial: false,
    heading: "",
    description: "",
    active: false,
    images: ref(),
    latitude: undefined,
    longitude: undefined,
    altitude: undefined,
  });
  location = ref("");
  rules = reactive({
    height: [
      { required: true, message: "Please input height", trigger: "blur" },
    ],
    scale: [{ required: true, message: "Please input scale", trigger: "blur" }],
    heading: [
      { required: true, message: "Please input heading", trigger: "blur" },
    ],
    latitude: [
      { required: true, message: "Please input latitude", trigger: "blur" },
    ],
    longitude: [
      { required: true, message: "Please input longitude", trigger: "blur" },
    ],
    altitude: [
      { required: true, message: "Please input altitude", trigger: "blur" },
    ],
    description: [
      {
        required: true,
        message: "Please input name",
        trigger: "blur",
      },
    ],
  });
  id;
  currentPageData;
  orgData;
  rendered = false;

  uploader = ref<InstanceType<typeof MitUploader>>();

  created() {
    this.$store.dispatch("getModelCategoryList");
    this.id = this.$route.query.id;
    if (this.id) {
      this.$store.dispatch("getStages", { Id: this.id }).then((res) => {
        if (res.data.length <= 0) {
          return;
        }
        const data = (this.orgData = res.data[0]);
        this.currentPageData = res.data;
        this.ruleForm.description = data.description;
        this.ruleForm.height = data.height;
        this.ruleForm.scale = data.scale;
        this.ruleForm.isGeospatial = data.isGeospatial;
        this.ruleForm.heading = data.heading;
        this.ruleForm.active = !data.isInactive;

        this.ruleForm.latitude = data.latitude;
        this.ruleForm.longitude = data.longitude;
        this.ruleForm.altitude = data.altitude;
        this.type = data.stageType;
        this.selectedAsset = data.previewAsset;
        if (data.stageType === "VerticalCarousel") {
          this.ruleForm.images = ref<UploadUserFile[]>(
            data?.verticalCarousel?.images
          );
        }
        this.rendered = true;
      });
    } else {
      this.rendered = true;
    }
  }
  updateVisible(val) {
    this.dialogVisible = val;
  }
  updateFocusOn(ev) {
    this.location = ev?.location;
    this.ruleForm.longitude = ev.longitude;
    this.ruleForm.latitude = ev.latitude;
    this.ruleForm.altitude = ev.elevation;
  }
  setElevation(ev) {
    this.ruleForm.altitude = ev;
  }

  changeType = (ev) => {
    this.type = ev;
  };

  changeCategory(ev) {
    this.categoryType = ev;
    this.$store
      .dispatch("getAsset", { CategoryId: this.categoryType })
      .then((res: InfiniteArray) => {
        this.shownAsset = res.data;
      });
  }

  selectAsset(ev) {
    this.selectedAsset = ev;
    this.dialogVisible = false;
  }

  submitForm(formEl: FormInstance | undefined) {
    if (!formEl) return;
    formEl.validate((valid) => {
      if (valid) {
        this.submit();
      }
    });
  }

  search() {
    const formVal = toJson(this.ruleForm);
    const location = new window.google.maps.LatLng(
      Number(formVal.latitude),
      Number(formVal.longitude)
    );
    const request = {
      location: location,
      radius: 5000,
      query: this.location,
    };
    EventBus.emit("google-text-search", request);
    EventBus.emit("get-elevation", location);
  }

  submit() {
    const formData = toJson(this.ruleForm);
    const data = {
      ...formData,
      isInactive: !formData.active,
      visibleRadius: 200,
    };
    switch (this.type) {
      case "Normal": {
        if (!this.selectedAsset) {
          ElMessage.error("Please select the AR to display");
          return;
        }
        const normalSubmit = {
          ...data,
          previewAssetId: this.selectedAsset?.id,
        };
        this.$store
          .dispatch(
            this.id ? "updateStage" : "postStage",
            this.id ? { param: normalSubmit, id: this.id } : normalSubmit
          )
          .then(() => {
            this.navigateTo("/vps-placement");
          });
        break;
      }

      case "VerticalCarousel": {
        const uploadList = (this.$refs.uploader as MitUploader).fileList;
        if (!data.images && !uploadList?.length) {
          ElMessage.error("Please upload images");
          return;
        }
        if (uploadList && uploadList.length > 0) {
          this.$store
            .dispatch("multiDetailUpload", {
              fileArray: uploadList,
              mergeArray: true,
            })
            .then((res: Array<any>) => {
              const _images = res.map((item) => {
                return {
                  url: item?.url || item?.origin?.url,
                  height: item?.height || item?.origin?.pixel?.height,
                  width: item?.width || item?.origin?.pixel?.width,
                  size: item?.size || item?.origin?.size,
                };
              });
              const vcSubmit = {
                ...data,
                speed: 1,
                images: _images,
              };
              this.$store
                .dispatch(
                  this.id ? "updateVCStage" : "postVCStage",
                  this.id ? { param: vcSubmit, id: this.id } : vcSubmit
                )
                .then(() => {
                  this.navigateTo("/vps-placement");
                });
            });
        } else {
          const vcSubmit = {
            ...data,
            speed: 1,
          };
          this.$store
            .dispatch(
              this.id ? "updateVCStage" : "postVCStage",
              this.id ? { param: vcSubmit, id: this.id } : vcSubmit
            )
            .then(() => {
              this.navigateTo("/vps-placement");
            });
        }

        break;
      }
    }
  }
  navigateTo(path) {
    this.$router.push(path);
  }
}

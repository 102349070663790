
import { Vue } from "vue-class-component";
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import type { FormInstance } from "element-plus";
import { checkEmail, validatePass, validatePass2 } from "@/utils/validation";
import { toJson } from "@/utils/utility";
import { ResetPassword } from "@/model/user.model";
import router from "@/router";

export default class ForgotPasswordView extends Vue {
  ruleForm = reactive({
    email: "",
    code: "",
    checkPass: "",
    password: "",
  });
  countDown = 0;
  step = 1;
  store = useStore();
  router = router;
  ruleFormRef = ref<FormInstance>();
  validatePass = (rule: any, value: any, callback: any) => {
    validatePass(
      rule,
      value,
      callback,
      this.ruleForm.checkPass,
      this.ruleFormRef,
      "checkPass"
    );
  };
  validatePass2 = (rule: any, value: any, callback: any) => {
    validatePass2(rule, value, callback, this.ruleForm.password);
  };
  rules = reactive({
    email: [{ validator: checkEmail, trigger: "blur" }],
    code: [
      {
        required: true,
        message: "Please input verification code",
        trigger: "blur",
      },
    ],
    password: [{ validator: this.validatePass, trigger: "blur" }],
    checkPass: [{ validator: this.validatePass2, trigger: "blur" }],
  });
  sendCode(formEl: any) {
    formEl.validateField("email", (isValid) => {
      if (isValid) {
        this.countDown = 30;
        const timer = setInterval(() => {
          this.countDown--;
          if (this.countDown === 0) {
            clearInterval(timer);
          }
        }, 1000);
        // updating, need a count down.
        this.store
          .dispatch("sendResetPasswordCode", this.ruleForm.email)
          .then(() => {
            this.step = 2;
          });
      }
    });
  }
  submitForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    formEl.validate((valid) => {
      if (valid) {
        const formParams = new ResetPassword(toJson(this.ruleForm));
        this.store.dispatch("resetPassword", formParams).then(() => {
          this.router.push({ path: "/login" });
        });
      } else {
        console.log("error submit!");
        return false;
      }
    });
  };
}


import { FormInstance } from "element-plus";
import { ref, reactive } from "vue";
import { Options, Vue } from "vue-class-component";
import EventBus from "@/utils/eventBus";
import { toJson } from "@/utils/utility";

@Options({
  components: {},
})
export default class MapSearchForm extends Vue {
  ruleFormRef = ref<FormInstance>();
  ruleForm = reactive({
    name: "",
    lat: null,
    lng: null,
    radius: null,
  });
  search() {
    const formVal = toJson(this.ruleForm);
    const location = new window.google.maps.LatLng(
      Number(formVal.lat),
      Number(formVal.lng)
    );
    const request = {
      location: location,
      radius: formVal.radius ? formVal.radius : 5000,
      query: formVal.name,
    };
    EventBus.emit("google-text-search", request);
  }
}

import { ElLoading, ElMessage } from "element-plus";
import StageService from "@/api/stage.service";

const openFullScreen2 = () => {
  return ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
};
const Stage = {
  state: {
    currentPageStage: [],
    totalStageCount: 0,
  },
  mutations: {
    setCurrentPageStage(state, res) {
      state.currentPageStage = res.data;
      state.totalStageCount = res.totalCount;
    },
  },
  actions: {
    getStages(context, param) {
      const loading = openFullScreen2();
      return StageService.getStages(param)
        .then((res) => {
          context.commit("setCurrentPageStage", res);
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    postStage(context, data) {
      const loading = openFullScreen2();
      return StageService.postStage(data)
        .then((res) => {
          ElMessage.success("VPS created successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    updateStage(context, { param: data, id: id }) {
      const loading = openFullScreen2();
      return StageService.updateStage(data, id)
        .then((res) => {
          ElMessage.success("VPS created successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    updateVCStage(context, { param: data, id: id }) {
      const loading = openFullScreen2();
      return StageService.updateVCStage(data, id)
        .then((res) => {
          ElMessage.success("VPS created successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
    postVCStage(context, data) {
      const loading = openFullScreen2();
      return StageService.postVCStage(data)
        .then((res) => {
          ElMessage.success("VPS created successfully");
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },

    deleteStage(context, id) {
      const loading = openFullScreen2();
      return StageService.deleteStage(id)
        .then((res) => {
          return res;
        })
        .catch((imError) => {
          throw imError;
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};

export default Stage;

import Request from "../utils/request";
import { FILE_API } from "../../environment";

let service = {
  multiUpload: function (fileArray) {
    const formParams = new FormData();
    fileArray.forEach((item) => {
      formParams.set(item.name, item.file);
    });
    return Request({
      url: "/FileUpload/Multiple/Query",
      method: "post",
      baseURL: FILE_API,
      headers: {
        "Content-Disposition": "form-data",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
      data: formParams,
      withCredentials: false,
    });
  },

  singleUpload: function (file) {
    const formParams = new FormData();
    formParams.set(file.name, file.file);
    return Request({
      url: "/FileUpload/Single/Query",
      method: "post",
      baseURL: FILE_API,
      headers: {
        "Content-Disposition": "form-data",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
      data: formParams,
      withCredentials: false,
    });
  },

  multiDetailUpload: function (fileArray) {
    const formParams = new FormData();
    fileArray.forEach((item) => {
      formParams.set(item.name, item.file);
    });
    return Request({
      url: "/FileUpload/Multiple/Detail/Query",
      method: "post",
      baseURL: FILE_API,
      headers: {
        "Content-Disposition": "form-data",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("platform_accesstoken"),
      },
      data: formParams,
      withCredentials: false,
    });
  },
};

export default service;

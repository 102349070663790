import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!

  return (_ctx.type === _ctx.ButtonType.HighLight)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: "high-light-btn",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick()))
      }, _toDisplayString(_ctx.name), 1))
    : (_openBlock(), _createBlock(_component_el_button, {
        key: 1,
        round: "",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClick())),
        size: "large",
        class: _normalizeClass(["ani base-button", [_ctx.size, _ctx.btnStyle, _ctx.disabled ? 'disabled' : '']]),
        disabled: _ctx.disabled
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.name), 1)
        ]),
        _: 1
      }, 8, ["class", "disabled"]))
}
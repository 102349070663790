
import { Options, Vue } from "vue-class-component";
import { sortBy } from "lodash";

@Options({
  created() {
    this.$store.dispatch("getFAQ");
  },
  mounted() {
    window.scroll(0, 0);
  },
  computed: {
    qaList() {
      const orgFaq = this.$store.state.global.faqs;
      if (!orgFaq) {
        return [];
      }
      return sortBy(orgFaq, (item) => {
        return item.order;
      });
    },
  },
})
export default class FAQsView extends Vue {}


import { Options, Vue } from "vue-class-component";
import { reactive, ref } from "vue";

@Options({
  components: {},
  computed: {},
})
export default class PurchaseHistoryView extends Vue {
  currentPageData = ref();
  ruleForm = reactive({
    // description: null,
    payStatus: null,
    date: null,
  });
  pageIndex = 1;
  readonly payStatusOptions = [
    { label: "Collected", value: "Successed" },
    { label: "Fail to receive", value: "Failed" },
    { label: "Processing", value: "Processing" },
    { label: "Cancelled", value: "Cancelled" },
  ];
  tableCellClassName = ({ row, column, rowIndex, columnIndex }) => {
    if (columnIndex === 3) {
      return "primary";
    }
    if (columnIndex === 4) {
      switch (row?.payStatus) {
        case "Successed":
          return "primary";
        case "Failed":
        case "Cancelled":
          return "failed";
        case "Processing":
          return "processing";
      }
    }
    return "";
  };
  created() {
    this.search();
  }
  dateLocalStringFormatter = (row: any, column: any, cellValue: any) => {
    return cellValue ? new Date(cellValue).toLocaleDateString() : "-";
  };

  moneyFormatter = (row: any, column: any, cellValue: any) => {
    return `${cellValue.toFixed(2)} USD`;
  };

  statusFormatter = (row: any, column: any, cellValue: any) => {
    const status = this.payStatusOptions.find((item) => {
      return cellValue === item.value;
    });
    return status?.label || "-";
  };

  search(pageIndex = 1) {
    this.pageIndex = pageIndex;
    const data = this.ruleForm
      ? {
          PageIndex: this.pageIndex,
          PayStatus: this.ruleForm.payStatus,
          CreatedStartAt: this.ruleForm?.date
            ? new Date(this.ruleForm?.date[0]).toISOString()
            : null,
          CreatedEndAt: this.ruleForm?.date
            ? new Date(this.ruleForm?.date[1]).toISOString()
            : null,
        }
      : null;

    this.$store.dispatch("getSubscription", data).then((res) => {
      this.currentPageData = res;
    });
  }
}

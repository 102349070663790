
import { Options, Vue } from "vue-class-component";
import { reactive, ref } from "vue";
import { FormInstance } from "element-plus";
import { useStore } from "vuex";
import { SignalRGame } from "@/model/game.model";
import { SignalRGameAction } from "@/utils/constant.data";

@Options({
  data() {
    return {
      Action: SignalRGameAction,
    };
  },
  props: {
    gameIdSource: String,
  },
  watch: {
    gameIdSource(newVal) {
      this.ruleForm.joinGameId = newVal;
      this.ruleForm.leaveGameId = newVal;
    },
  },
})
export default class JoinLeaveGameView extends Vue {
  store = useStore();
  ruleFormRef = ref<FormInstance>();

  ruleForm = reactive({
    joinGameId: "",
    leaveGameId: "",
  });
  rules = reactive({
    joinGameId: [
      { required: true, message: "Please input Product ID", trigger: "blur" },
    ],
    leaveGameId: [
      { required: true, message: "Please input Product ID", trigger: "blur" },
    ],
  });
  leaveAll() {
    // this.$emit("leaveAll");
    this.$emit("trigger", new SignalRGame(SignalRGameAction.LeaveAllGame));
  }
  submitForm(formEl: FormInstance | undefined, action, fieldName) {
    if (!formEl) return;
    formEl.validateField(fieldName, (valid) => {
      if (valid) {
        this.$emit(
          "trigger",
          new SignalRGame(action, this.ruleForm[fieldName])
        );
      } else {
        console.log("error submit!");
      }
    });
  }
}


import { formInvalidHandler, toJson } from "@/utils/utility";
import { ElMessage, FormInstance, UploadUserFile } from "element-plus";
import { ref, reactive } from "vue";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import MitUploaderVue from "@/components/ui-library/MitUploader.vue";
import { UploadItem } from "@/model/upload.model";
import { Submission } from "@/model/submission.model";
import router from "@/router";
import { UploadFileType } from "@/utils/constant.data";
import { checkNumNonnegative } from "@/utils/validation";
import MitTag from "@/components/ui-library/MitTag.vue";

@Options({
  components: {
    "mit-uploader": MitUploaderVue,
    MitTag,
  },


  mounted() {
    window.scrollTo(0, 0);
    if (!this.$route.query.id) {
      this.rendered = true;
      return;
    }
    this.store.dispatch("getProductItem", this.$route.query.id).then((res) => {
      this.id = res.id;
      this.ruleForm.name = res.name;
      this.ruleForm.tokensToPlay = res.tokensToPlay;
      this.ruleForm.maxNumberOfPlayers = res.maxNumberOfPlayers;
      this.ruleForm.minNumberOfPlayers = res.minNumberOfPlayers;
      this.ruleForm.introduce = res.introduce;
      this.ruleForm.description = res.description;
      this.ruleForm.price = res.price;
      this.ruleForm.avatar = ref<UploadUserFile[]>([
        { name: res.name, url: res.thumbnail.url },
      ]);
      if (res.coverThumbnail) {
        this.ruleForm.cover = ref<UploadUserFile[]>([
          { name: "cover", url: res.coverThumbnail.url },
        ]);
      }
      this.ruleForm.iconUrl = res?.icon?.url;
      this.ruleForm.thumbnailUrl = res?.thumbnail?.url;
      this.ruleForm.smallThumbnailUrl = res?.smallThumbnail?.url;
      this.ruleForm.coverUrl = res?.coverThumbnail?.url;
      this.ruleForm.tags = res?.tags.map((item) => {
        return item.name;
      });
      this.rendered = true;
    });
  },
})
export default class SubmissionView extends Vue {
  readonly UploadFileType = UploadFileType;
  id = ref();
  btnName = ref();
  ruleFormRef = ref<FormInstance>();
  store = useStore();
  ruleForm = reactive({
    name: "",
    tokensToPlay: "",
    maxNumberOfPlayers: "",
    minNumberOfPlayers: "",
    price: "",
    introduce: "",
    description: "",

    avatar: ref(), // for show
    cover: ref(),

    //real data
    iconUrl: "",
    thumbnailUrl: "",
    smallThumbnailUrl: "",
    coverUrl: "",
    tags: [],
  });
  // upload file source
  avatar = ref();

  router = router;
  editMode = false;
  coverSource = ref();
  rendered = false;

  created() {
    const id = this.$route.query.id;
    if (id) {
      this.editMode = true;
      this.btnName = ref("Update Project");
    } else {
      this.btnName = ref("Create Project");
    }
  }

  checkNumber = (
    rule: any,
    value: any,
    callback: any,
    greaterThan?: boolean
  ) => {
    if (!value) {
      return callback(new Error(`Please input the positive integer`));
    }
    if (!Number.isInteger(Number(value))) {
      callback(new Error("Please input digits"));
    } else if (value < 1) {
      callback(new Error("Must be non negative"));
    } else if (
      greaterThan === true &&
      Number(value) < Number(this.ruleForm.minNumberOfPlayers)
    ) {
      callback(new Error("Must be garter than minimum number of players"));
    } else if (
      greaterThan === false &&
      Number(value) > Number(this.ruleForm.maxNumberOfPlayers)
    ) {
      callback(new Error("Must be less than maximum number of players"));
    } else {
      if (greaterThan === true) {
        this.ruleFormRef["validateField"]("minNumberOfPlayers", () => null);
      } else if (greaterThan === false) {
        this.ruleFormRef["validateField"]("maxNumberOfPlayers", () => null);
      }
      callback();
    }
  };

  lessThanMax = (rule: any, value: any, callback: any) => {
    this.checkNumber(rule, value, callback, false);
  };

  graterThanMin = (rule: any, value: any, callback: any) => {
    this.checkNumber(rule, value, callback, true);
  };

  rules = reactive({
    name: [
      { required: true, message: "Please input project name", trigger: "blur" },
    ],
    tokensToPlay: [{ validator: checkNumNonnegative, trigger: "blur" }],
    minNumberOfPlayers: [{ validator: this.lessThanMax, trigger: "blur" }],
    maxNumberOfPlayers: [{ validator: this.graterThanMin, trigger: "blur" }],
    avatar: [
      { required: true, message: "Please upload thumbnail", trigger: "change" },
    ],
    cover: [
      {
        required: true,
        message: "Please upload cover image",
        trigger: "blur",
      },
    ],
    price: [{ validator: checkNumNonnegative, trigger: "blur" }],
  });

  submitForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    formEl.validate((valid, fields) => {
      if (valid) {
        this.submit();
      } else {
        formInvalidHandler(fields);
        return false;
      }
    });
  };

  submit() {
    const formVal = toJson(this.ruleForm);
    const fileArray: any[] = [];
    this.coverSource &&
      fileArray.push(new UploadItem("coverThumbnailUrl", this.coverSource));
    this.avatar && fileArray.push(new UploadItem("avatar", this.avatar));

    if (fileArray.length > 0) {
      this.store.dispatch("multiUpload", fileArray).then((res: Array<any>) => {
        const subData = new Submission(
          formVal,
          res.find((item) => item.name === "avatar"),
          res.find((item) => item.name === "coverThumbnailUrl")
        );
        if (this.editMode) {
          this.store
            .dispatch("updateProduct", { body: subData, id: this.id })
            .then((data) => {
              this.router.push({
                name: "Detail",
                query: { name: data.name, id: this.id as any, type: "AR Game" },
              });
            });
        } else {
          this.store.dispatch("createProduct", subData).then((data) => {
            this.router.push({
              name: "Detail",
              query: { name: data.name, id: data.id as any, type: "AR Game" },
            });
          });
        }
      });
    } else if (this.editMode) {
      const updateData = new Submission(formVal);
      this.store
        .dispatch("updateProduct", { body: updateData, id: this.id })
        .then((data) => {
          this.router.push({
            name: "Detail",
            query: { name: data.name, id: this.id as any, type: "AR Game" },
          });
        });
    }
  }

  fileChange(ev, fileType: string) {
    this[fileType] = ev.raw;
    setTimeout(() => {
      (this.ruleFormRef as any).validateField(
        fileType === "avatar" ? "avatar" : "cover"
      );
    }, 400);
  }
  updateTags(value) {
    this.ruleForm.tags = value;
  }
}


import { Options, Vue } from "vue-class-component";
import { ElMessage } from "element-plus";

@Options({
  mounted() {
    window.scrollTo(0, 0);
  },
})
export default class StartSubmissionView extends Vue {
  navigateTo(name) {
    this.$router.push({ name: name });
  }
}

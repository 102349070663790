export class RegisterUser {
  email: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  password: string | undefined;
  verificationCode: string | undefined;
  introduce: string | undefined;
  registeredBusinessName: string;
  registeredAddress: string;
  constructor(data) {
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.password = data.password;
    this.verificationCode = data.verificationCode;
    this.introduce = data.introduce;
    this.registeredBusinessName = data.registeredBusinessName;
    this.registeredAddress = data.registeredAddress;
  }
}

export class ResetPassword {
  email: string | undefined;
  password: string | undefined;
  verificationCode: string | undefined;
  constructor(data) {
    this.email = data.email;
    this.password = data.password;
    this.verificationCode = data.code;
  }
}


import { Options, Vue } from "vue-class-component";
import { marked } from "marked";

@Options({
  components: {},
  mounted() {
    window.scrollTo(0, 0);
  },
  data() {
    return {
      msg: "",
    };
  },
  created() {
    this.$store.dispatch("getDoclist").then(() => {
      this.menu && this.showDoc(this.menu[0].url);
    });
  },
  computed: {
    documentsSrouce() {
      return this.$store.state.global.docs;
    },
    menu() {
      const array = this.documentsSrouce.sort((a, b) => {
        return a - b;
      });

      return array.map((item) => {
        return {
          description: item.description,
          id: item.id,
          url: item.url,
          name: item.name,
        };
      });
    },
  },
  methods: {
    showDoc(url) {
      this.$store.dispatch("getMdSource", url).then((res) => {
        this.msg = marked(res);
      });
    },
  },
})
export default class DocumentView extends Vue {}


import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import MitDialogVue from "@/components/ui-library/MitDialog.vue";
import { TemplateType } from "@/utils/constant.data";

@Options({
  components: {
    "mit-dialog": MitDialogVue,
  },
})
export default class FooterView extends Vue {
  tcAndpp = ref(false);
  dialogVisible = false;
  dialogTitle = "";
  readonly TemplateType = TemplateType;
  dialogText = "";
  yearNum = new Date().getFullYear();

  navigateTo(ev) {
    const iosLink = "https://apps.apple.com/hk/app/mmita/id1522417329";
    const androidLink =
      "https://play.google.com/store/apps/details?id=com.Bamb.MMITA";
    ev === "ios" ? window.open(iosLink) : window.open(androidLink);
  }
  showDialog(type: TemplateType) {
    switch (type) {
      case TemplateType.DeveloperAgreement:
        this.dialogTitle = "Developer Agreement";
        break;
      case TemplateType.MarketplaceAgreement:
        this.dialogTitle = "Marketplace Agreement";
        break;
      case TemplateType.MarketplaceTermsofUse:
        this.dialogTitle = "Marketplace Terms of Use";
    }
    this.$store
      .dispatch("getTemplate", type)
      .then((res) => {
        this.dialogText = res;
        this.dialogVisible = true;
        setTimeout(() => {
          const ele = document.getElementById("dialogInner1") as any;
          ele.scrollTop = 0;
        }, 0);
      })
      .catch(() => {
        this.dialogVisible = false;
      });
  }
  updateVisible(val) {
    this.dialogVisible = val;
  }
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-196d6a9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $options.dialogVisible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.dialogVisible) = $event)),
    title: $props.title,
    "show-close": false,
    class: "mit-dialog",
    onClose: $options.onClose,
    style: _normalizeStyle({
      borderRadius: `var(--el-border-radius-round)`,
    })
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString($props.title), 1)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["modelValue", "title", "onClose", "style"]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderNav = _resolveComponent("HeaderNav")!
  const _component_HeaderBanner = _resolveComponent("HeaderBanner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterBar = _resolveComponent("FooterBar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderNav, { class: "nav-bar" }),
    (_ctx.showBanner)
      ? (_openBlock(), _createBlock(_component_HeaderBanner, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("section", {
      class: _normalizeClass(["view-wrapper", { 'full-page': _ctx.fullPage }])
    }, [
      _createVNode(_component_router_view, { class: "view-container" })
    ], 2),
    _createVNode(_component_FooterBar, { class: "footer" })
  ], 64))
}
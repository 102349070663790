import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53be0e23"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner-wrapper" }
const _hoisted_2 = { class: "sub-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_mit_button = _resolveComponent("mit-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.createMode ? "Create Game" : "Restart Game"), 1),
    _createVNode(_component_el_form, {
      ref: "ruleFormRef",
      model: _ctx.ruleForm,
      "status-icon": "",
      rules: _ctx.rules,
      class: "form",
      size: 'large',
      "label-position": "top"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, { gutter: 10 }, {
          default: _withCtx(() => [
            (!_ctx.createMode)
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 0,
                  span: 6
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      prop: "gameId",
                      label: "Game ID",
                      required: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.ruleForm.gameId,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ruleForm.gameId) = $event)),
                          type: "text",
                          autocomplete: "off"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  prop: "gameType",
                  label: "Game Type"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.ruleForm.gameType,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ruleForm.gameType) = $event)),
                      clearable: "",
                      placeholder: "Select"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gameTypeOptions, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.value,
                            label: item.label,
                            value: item.value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  prop: "productId",
                  label: "Product ID",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.ruleForm.productId,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.ruleForm.productId) = $event)),
                      type: "text",
                      autocomplete: "off"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  prop: "totalTime",
                  label: "Total Time",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.ruleForm.totalTime,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.ruleForm.totalTime) = $event)),
                      type: "number",
                      autocomplete: "off"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  prop: "roundInfoMin",
                  label: "Minimum seconds of round",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.ruleForm.roundInfoMin,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.ruleForm.roundInfoMin) = $event)),
                      type: "number",
                      autocomplete: "off"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  prop: "roundInfoMax",
                  label: "Maximum seconds of round",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.ruleForm.roundInfoMax,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.ruleForm.roundInfoMax) = $event)),
                      type: "number",
                      autocomplete: "off"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  prop: "totalRounds",
                  label: "Total No. of Round",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.ruleForm.totalRounds,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.ruleForm.totalRounds) = $event)),
                      type: "number",
                      autocomplete: "off"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.createMode)
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 1,
                  span: 6
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      prop: "arId",
                      label: "AR ID",
                      required: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.ruleForm.arId,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.ruleForm.arId) = $event)),
                          type: "text",
                          autocomplete: "off"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  prop: "productVersion",
                  label: "Product Version",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.ruleForm.productVersion,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.ruleForm.productVersion) = $event)),
                      type: "text",
                      autocomplete: "off"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 24 }, {
              default: _withCtx(() => [
                _createVNode(_component_mit_button, {
                  onOnClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.submitForm(_ctx.ruleFormRef))),
                  name: _ctx.createMode ? 'Create Game' : 'Restart Game',
                  size: 'lg',
                  btnStyle: 'blank-btn'
                }, null, 8, ["name"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}
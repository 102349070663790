import {
  ProductStatus,
  ProductVersionStatus,
  VersionStateActionBtn,
  StatusFormatted,
  StatusRowClass,
} from "@/utils/constant.data";

export class Submission {
  description: string;
  name: string;
  maxNumberOfPlayers: number;
  tokensToPlay: number;
  introduce: string;
  iconUrl: string;
  thumbnailUrl: string;
  smallThumbnailUrl: string;
  minNumberOfPlayers: number;
  coverThumbnailUrl: string;
  price: number;
  tags: Array<string>;

  constructor(formData, avaterUrls?, cover?) {
    this.description = formData.description;
    this.name = formData.name;
    this.maxNumberOfPlayers = formData.maxNumberOfPlayers;
    this.tokensToPlay = formData.tokensToPlay;
    this.introduce = formData.introduce;
    this.minNumberOfPlayers = formData.minNumberOfPlayers;
    this.price = formData.price;

    this.iconUrl = avaterUrls?.icon ? avaterUrls.icon : formData.iconUrl;
    this.thumbnailUrl = avaterUrls?.medium
      ? avaterUrls.medium
      : formData.thumbnailUrl;
    this.smallThumbnailUrl = avaterUrls?.small
      ? avaterUrls.small
      : formData.smallThumbnailUrl;

    this.coverThumbnailUrl = cover ? cover.medium : formData.coverUrl;
    this.tags = formData.tags;
  }
}

export class ThreeModelSubmission {
  description: string;
  name: string;
  iconUrl: string;
  thumbnailUrl: string;
  smallThumbnailUrl: string;
  coverThumbnailUrl: string;
  categoryId: string;
  price: number;
  tags: Array<string>;

  constructor(formData, avaterUrls?, cover?) {
    this.description = formData.description;
    this.name = formData.name;
    this.categoryId = formData.categoryId;
    this.price = formData.price;

    this.iconUrl = avaterUrls?.icon ? avaterUrls.icon : formData.iconUrl;
    this.thumbnailUrl = avaterUrls?.medium
      ? avaterUrls.medium
      : formData.thumbnailUrl;
    this.smallThumbnailUrl = avaterUrls?.small
      ? avaterUrls.small
      : formData.smallThumbnailUrl;

    this.coverThumbnailUrl = cover ? cover.medium : formData.coverUrl;
    this.tags = formData?.tags;
  }
}

class ProductVersionItem {
  productVersion: string | undefined;
  createDateStr: string;
  reviewStatus: string;
  statusRowClass: string;
  modelVersion: string;

  constructor(data) {
    data;
    // product and moduel use the same constructor
    this.productVersion = data.productVersion;
    this.createDateStr = new Date(data.createdAt).toLocaleDateString();
    this.reviewStatus = ProductStatus[data.reviewStatus];
    this.statusRowClass = StatusRowClass[data.reviewStatus];
    this.modelVersion = data.modelVersion; // for model
  }
}

export class ProductVersionList {
  constructor(array) {
    return array.map((item) => {
      return new ProductVersionItem(item);
    });
  }
}

export class ReleaseVersionData extends ProductVersionItem {
  btnName: string;
  status: string;
  unapproveReason: string;
  statusAfterFormatted: string;
  constructor(data) {
    super(data);
    this.btnName = VersionStateActionBtn[data.status].btnName;
    this.status = data.status;
    this.unapproveReason = data.unapproveReason;
    this.statusAfterFormatted = StatusFormatted[data.status];
  }
}

export class VersionModel {
  updateLog: string | undefined;
  iosAssetUrl: string | undefined;
  androidAssetUrl: string | undefined;
  visionOSAssetUrl: string | undefined;
  macOSAssetUrl: string | undefined;
  constructor(data, fileUrl?) {
    this.updateLog = data.updateLog;
    this.iosAssetUrl = fileUrl?.iosAssetUrl
      ? fileUrl.iosAssetUrl
      : data.iosAssetUrl;
    this.androidAssetUrl = fileUrl?.androidAssetUrl
      ? fileUrl.androidAssetUrl
      : data.androidAssetUrl;
    this.visionOSAssetUrl = fileUrl?.visionOSAssetUrl
      ? fileUrl.visionOSAssetUrl
      : data.visionOSAssetUrl;
    this.macOSAssetUrl = fileUrl?.macOSAssetUrl || data?.macOSAssetUrl;
  }
}

export class ProductVersionCreateMode {
  productVersion: number;
  productId: string;
  constructor(productVersion, productId) {
    this.productVersion = productVersion;
    this.productId = productId;
  }
}

export class ModelVersionCreateMode {
  modelId: string;
  modelVersion: number;
  constructor(modelVersion, modelId) {
    this.modelId = modelId;
    this.modelVersion = modelVersion;
  }
}

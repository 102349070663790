import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-078e207f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "res-wrapper normal-termainal" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mit_terminal = _resolveComponent("mit-terminal")!

  return (_openBlock(), _createBlock(_component_mit_terminal, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.command, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["res-inner", item.msgSate])
          }, [
            _createTextVNode(_toDisplayString(`[${item.time}]`) + " ", 1),
            (item.msgSate !== 'info')
              ? (_openBlock(), _createElementBlock("b", _hoisted_2, _toDisplayString(`${item.actionName} ${item.succeeded ? "Succeeded" : "Failed"}`), 1))
              : _createCommentVNode("", true),
            (item.data.toString().length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(`Result:${JSON.stringify(item.data)}`), 1))
              : _createCommentVNode("", true)
          ], 2))
        }), 128))
      ])
    ]),
    _: 1
  }))
}
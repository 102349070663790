
import { Options, Vue } from "vue-class-component";
import EventBus from "@/utils/eventBus";
import { loader } from "@/utils/constant.data";
import { ElMessage } from "element-plus";
@Options({
  props: {
    locationInfos: Array,
    focusOnMarker: { lat: Number, lng: Number },
  },
  watch: {
    locationInfos(newV, oldV) {
      this.genIconMarker();
    },
    focusOnMarker(newV, oldV) {
      this.setCenter(newV);
      this.setZoom();
    },
  },
  unmounted() {
    EventBus.off("google-text-search");
    EventBus.off("get-elevation");
  },
})
export default class MitMap extends Vue {
  private map;
  private googlePlaceService;
  private elevationService;
  private infoWindow;

  private locationInfos;
  private markerArray: Array<any> = [];
  created() {
    EventBus.on("google-text-search", (val) => {
      this.searchByText(val);
    });
    EventBus.on("get-elevation", (val) => {
      this.getElevation(val).then((elevation) => {
        this.$emit("elevationVal", elevation);
      });
    });

    this.loadMap();
  }

  loadMap() {
    loader.load().then((google) => {
      this.map = new google.maps.Map(document.getElementById("map") as any, {
        center: { lat: 23.1291, lng: 113.2644 }, //default location
        zoom: 3,
      });
      this.getCurrentLoaction();
      this.googlePlaceService = new google.maps.places.PlacesService(this.map);
      this.infoWindow = new google.maps.InfoWindow();
      this.elevationService = new google.maps.ElevationService();
      this.genIconMarker();
      this.map.addListener("click", (mapsMouseEvent) => {
        const latLng = mapsMouseEvent.latLng.toJSON();
        this.getElevation(latLng).then((altitude) => {
          this.showLocationInfo(mapsMouseEvent, altitude);
          this.$emit("focusOn", {
            latitude: mapsMouseEvent.latLng.lat(),
            longitude: mapsMouseEvent.latLng.lng(),
            elevation: altitude,
          });
        });
      });
    });
  }

  getCurrentLoaction() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.map.setCenter(pos);
      });
    }
  }

  showLocationInfo(mapsMouseEvent, altitude) {
    this.infoWindow.close();
    this.infoWindow.setPosition(mapsMouseEvent.latLng);
    this.infoWindow.setContent(
      `latitude:${mapsMouseEvent.latLng.lat()} , longitude:${mapsMouseEvent.latLng.lng()} , altitude:${altitude}`
    );
    this.infoWindow.open(this.map);
  }

  genIconMarker() {
    this.clearMarker();
    if (!this.locationInfos || this.locationInfos.length < 1) {
      return;
    }
    this.locationInfos.forEach((item) => {
      this.addMarker(item);
    });

    this.setCenter({
      lat: this.locationInfos[0].latitude,
      lng: this.locationInfos[0].longitude,
    });
  }

  setInfoWindow(mapsMouseEvent, marker) {
    this.infoWindow.close();
    this.infoWindow.setPosition(mapsMouseEvent.latLng);
    if (!marker?.title) {
      return;
    }
    this.infoWindow.setContent(marker.title);
    this.infoWindow.open(this.map);
  }

  searchByText(request) {
    const callback = (results, status) => {
      if (status == window.google.maps.places.PlacesServiceStatus.OK) {
        this.clearMarker();
        this.setCenter(results[0]?.geometry?.location);
        this.setZoom();
        results.forEach((item) => {
          this.addMarker(item);
        });
        ElMessage.success(
          `Return ${results.length} markers for you to choose location`
        );
      } else {
        ElMessage.error("search failed");
      }
    };
    this.googlePlaceService.textSearch(request, callback);
  }

  addMarker(data) {
    if (!window.google?.maps) {
      return;
    }
    const marker = new window.google.maps.Marker({
      position: {
        lat: data?.latitude || data.geometry?.location?.lat() || 0,
        lng: data?.longitude || data.geometry?.location?.lng() || 0,
      },
      map: this.map,
      animation: window.google.maps.Animation.DROP,
      title: data.name,
      icon: data?.iconUrl,
      label: data?.description,
    }) as any;
    this.markerArray.push(marker);
    marker.addListener("click", (mapsMouseEvent) => {
      this.setInfoWindow(mapsMouseEvent, marker);
      this.getElevation(mapsMouseEvent.latLng.toJSON())
        .then((elevation) => {
          this.$emit("focusOn", {
            location: marker.title,
            latitude: marker?.position?.lat(),
            longitude: marker?.position?.lng(),
            elevation: elevation,
          });
        })
        .catch(() => {
          this.$emit("focusOn", {
            location: marker.title,
            latitude: marker?.position?.lat(),
            longitude: marker?.position?.lng(),
            elevation: null,
          });
        });
    });
  }

  setCenter(location) {
    this.map?.setCenter(location);
  }

  setZoom(zoom = 12) {
    this.map?.setZoom(zoom);
  }

  clearMarker() {
    this.markerArray.forEach((item) => {
      typeof item.setMap == "function" && item.setMap(null);
      item.visible = false; // for delete marker
    });
    this.markerArray = [];
  }

  getElevation(latLng) {
    return new Promise((resolve, reject) => {
      this.elevationService
        .getElevationForLocations({
          locations: [latLng],
        })
        .then((res) => {
          if (res && res.results) {
            resolve(res.results[0].elevation);
          } else {
            reject();
          }
        });
    });
  }
}


import { reactive, ref } from "vue";
import type { FormInstance } from "element-plus";
import { Options, Vue } from "vue-class-component";
import { toJson } from "@/utils/utility";
import { useStore } from "vuex";
import StripeView from "@/views/stripe-view/StripeView.vue";

@Options({
  components: {
    StripeView,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    connectedToStripe() {
      return this.store.state.user?.userProfile?.connectedToStripe;
    },
    alartInfo() {
      if (this.connectedToStripe) {
        return { title: "You have connected to stripe.", type: "success" };
      } else {
        return {
          title: "Click here to connect Stripe Account.",
          type: "warning",
        };
      }
    },
    orgData() {
      return this.store.state.user.userProfile;
    },

    ruleForm() {
      return reactive({
        firstName: this.orgData.firstName,
        lastName: this.orgData.lastName,
        introduce: this.orgData.introduce,
        registeredBusinessName: this.orgData.registeredBusinessName,
        registeredAddress: this.orgData.registeredAddress,
      });
    },
  },
})
export default class ProfileView extends Vue {
  ruleFormRef = ref<FormInstance>();
  store = useStore();

  rules = reactive({
    firstName: [
      { required: true, message: "Please input first name", trigger: "blur" },
    ],
    lastName: [
      { required: true, message: "Please input last name", trigger: "blur" },
    ],
    registeredAddress: [
      {
        required: true,
        message: "Please input registered address",
        trigger: "blur",
      },
    ],
  });
  connectedToStripe: any;

  created() {
    this.store.dispatch("getMyProfile");
  }

  submitForm = (formEl: FormInstance | undefined, ruleForm) => {
    const formVal = toJson(ruleForm);
    if (!formEl) return;
    formEl.validate((valid) => {
      if (valid) {
        this.store.dispatch("updateProfile", formVal);
      } else {
        return false;
      }
    });
  };

  navigateTo() {
    if (!this.connectedToStripe) {
      this.$router.push("/stripe");
    }
  }
}
